/* eslint-disable react/function-component-definition */
import { FC } from "react";
import tw from "twin.macro";
import { Button } from "src/shared/components/Button";
import NextLink from "next/link";

type Props = {
  variant?: "full" | "drawer";
};
export const CartEmpty: FC<Props> = ({ variant = "full" }) => {
  const isInDrawer = variant === "drawer";
  return (
    <div css={tw`flex items-center justify-center text-center`}>
      <div css={tw`mt-8`}>
        <p css={tw`mb-4`}>Your cart is currently empty.</p>
        {!isInDrawer && (
          <NextLink href="/" passHref legacyBehavior>
            <Button>Continue Shopping</Button>
          </NextLink>
        )}
      </div>
    </div>
  );
};
