/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */
// TODO: This file need to be updated
import React, { FC, useEffect, useMemo, useState } from "react";
import tw from "twin.macro";
import { ProductFieldsCartFragment } from "src/generated/datocms-types";
import {
  DELIVERY_DATE_LABEL,
  DELIVERY_DATE_PROP,
  ESTIMATED_DELIVERY_DATE_LABEL,
  FIRST_DELIVERY_DATE_LABEL,
  SATURDAY_NEXTDAY_DELIVERY_PROP,
  SATURDAY_DELIVERY_PROP,
  NEXTDAY_DELIVERY_PROP,
  VARIANT_TITLE_PROP,
  ONE_TIME_VARIANT_ID_PROP,
} from "src/features/Cart/constants";
import {
  cartOperations,
  useInvalidDeliveryDates,
} from "src/features/Cart/state";
import { parse, differenceInDays, format, addDays, addMinutes } from "date-fns";
import { DeliveryDatePicker } from "src/features/Cart/CartPage/DeliveryDatePicker";
import { useQuery } from "react-query";
import {
  DatePickerClientConfigT,
  loadDatePickerConfig,
} from "src/features/ProductForm/utils";
import { getNameValueProp } from "src/shared/utils";
import { NullableString } from "src/shared/types";
import produce from "immer";
import { isWeeklySuperGiftProduct } from "src/shared/utils/products";
import { b64decode } from "src/shared/helpers";
import { ShopifyLineItem, ShopifyVariantNode } from "src/shared/types/shopify";
import {
  nextdayDeliveryVariant,
  saturdayDeliveryVariant,
  saturdayNextDayDeliveryVariant,
} from "src/constants";

type Props = {
  lineItem: ShopifyLineItem;
  product: ProductFieldsCartFragment;
  variant?: "full" | "drawer";
  openSlideCalendar?: string;
  handleOpenOrCloseSlideCalendar?: (productId: string) => void;
};
export const DeliveryDate: FC<Props> = ({
  lineItem,
  product,
  variant = "full",
  openSlideCalendar,
  handleOpenOrCloseSlideCalendar,
}) => {
  const getCurrentOneTimeVariant = () => {
    const variantPropValue = lineItem.attributes?.find(
      (prop) => prop.key === ONE_TIME_VARIANT_ID_PROP
    );
    if (variantPropValue) {
      const variantIdPropValue = variantPropValue.value?.trim();
      const _currentOneTimeVariant = product.variants.find(
        (v) => v.id === variantIdPropValue
      );
      return _currentOneTimeVariant;
    }
    return product.variants[0];
  };
  const deliveryDate = useMemo(() => {
    const deliveryDateProp = getNameValueProp(
      lineItem?.attributes ?? [],
      DELIVERY_DATE_PROP
    );
    return deliveryDateProp
      ? parse(deliveryDateProp, "MM-dd-yyyy", new Date())
      : null;
  }, [lineItem]);
  const [deliveryDateValue, setDeliveryDateValue] = useState<NullableString>(
    deliveryDate ? format(deliveryDate, "MM-dd-yyyy") : null
  );
  const secondDeliveryDate = useMemo(() => {
    let secondDate = deliveryDateValue
      ? addDays(parse(deliveryDateValue, "MM-dd-yyyy", new Date()), 7)
      : null;
    if (secondDate?.getUTCDay() === 6) {
      secondDate = addDays(secondDate, -1);
    }
    return secondDate;
  }, [deliveryDateValue]);
  const thirdDeliveryDate = useMemo(() => {
    let thirdDate = secondDeliveryDate ? addDays(secondDeliveryDate, 7) : null;
    if (thirdDate?.getUTCDay() === 6) {
      thirdDate = addDays(thirdDate, -1);
    }
    return thirdDate;
  }, [secondDeliveryDate]);
  const isInDrawer = variant === "drawer";
  const { addInvalidDelivaryDate, removeInvalidDeliveryDate } =
    useInvalidDeliveryDates();
  const { isSubscription, id: productId } = product;
  const query = useQuery<DatePickerClientConfigT>(
    [{ productId, isSubscription }, "drawerdatepicker"],
    () =>
      loadDatePickerConfig({
        productId,
        isSubscription,
        userDate: new Date().toISOString(),
      })
  );
  const datePickerConfig = query.data ?? ({} as DatePickerClientConfigT);
  const [isValidDeliveryDate, setIsValidDeliveryDate] = useState(
    deliveryDate
      ? differenceInDays(new Date(datePickerConfig.minDate), deliveryDate) < 1
      : false
  );
  useEffect(() => {
    if (!query.isLoading) {
      if (
        !isValidDeliveryDate &&
        deliveryDate &&
        differenceInDays(new Date(datePickerConfig.minDate), deliveryDate) > 0
      ) {
        addInvalidDelivaryDate(lineItem);
      } else if (isValidDeliveryDate) {
        removeInvalidDeliveryDate(lineItem);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidDeliveryDate, query.isLoading]);
  if (query.error) {
    return (
      <div css={tw`text-sm font-roboto text-red-700`}>
        Something went wrong, please refresh the page and try again.
      </div>
    );
  }
  if (query.isLoading) {
    return <div css={tw`text-sm font-roboto`}>Loading delivery date...</div>;
  }
  if (!datePickerConfig) {
    return (
      <div css={tw`text-sm font-roboto`}>
        An error occurred, please refresh the page and try again.
      </div>
    );
  }
  const updateDeliveryDate = (date: string) => {
    const deliveryDatePropIndex =
      lineItem.attributes?.findIndex(
        (prop) => prop.key === DELIVERY_DATE_PROP
      ) ?? -1;
    if (lineItem.attributes && deliveryDatePropIndex > -1) {
      setDeliveryDateValue(date);
      setIsValidDeliveryDate(true);
      const newProps = [
        ...(lineItem.attributes?.slice(0, deliveryDatePropIndex) ?? []),
        {
          ...lineItem.attributes[deliveryDatePropIndex],
          value: date,
        },
        ...(lineItem.attributes?.slice(deliveryDatePropIndex + 1) ?? []),
      ];
      const variants = product.shopifyProduct.variants.edges;
      const selectedDate =
        date.length > 0 ? parse(date, "MM-dd-yyyy", new Date()) : null;
      const minumumDate = new Date(
        addMinutes(
          new Date(datePickerConfig.minDate ?? new Date()),
          new Date().getTimezoneOffset()
        )?.setHours(0, 0, 0, 0)
      );

      const saturdayNextDaySurcharge =
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) => v.node.saturdayNextDayFee?.value
        )?.node?.saturdayNextDayFee?.value ??
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) =>
            v.node.title.includes(saturdayNextDayDeliveryVariant)
        )?.node?.shipmentFee?.value;
      const nextDaySurcharge =
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) => v.node.nextDayFee?.value
        )?.node?.nextDayFee?.value ??
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) =>
            v.node.title.includes(nextdayDeliveryVariant)
        )?.node?.shipmentFee?.value;
      const saturdaySurcharge =
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) => v.node.saturdayFee?.value
        )?.node?.saturdayFee?.value ??
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) =>
            v.node.title.includes(saturdayDeliveryVariant)
        )?.node?.shipmentFee?.value;

      const isNextDayDeliveryAvailable =
        datePickerConfig?.nextDayShippingConfig?.enabled &&
        !datePickerConfig?.nextDayShippingConfig?.isExpired &&
        !datePickerConfig?.nextDayShippingConfig?.isExpedited;

      const isNextDayDeliverySurcharge =
        selectedDate?.getDate() === minumumDate?.getDate() &&
        selectedDate?.getMonth() === minumumDate?.getMonth() &&
        selectedDate?.getFullYear() === minumumDate?.getFullYear() &&
        datePickerConfig?.nextDayShippingConfig?.enabled;

      const isNextDayDelivery =
        selectedDate?.getDate() === minumumDate?.getDate() &&
        selectedDate?.getMonth() === minumumDate?.getMonth() &&
        selectedDate?.getFullYear() === minumumDate?.getFullYear() &&
        isNextDayDeliveryAvailable;

      const defaultVariant = product.shopifyProduct?.variants.edges[0]?.node;
      const nextDayVariant = product.shopifyProduct?.variants.edges.find(
        (v: { node: ShopifyVariantNode }) =>
          v.node.title === nextdayDeliveryVariant
      )?.node;
      const saturdayVariant = product.shopifyProduct?.variants.edges.find(
        (v: { node: ShopifyVariantNode }) =>
          v.node.title === saturdayDeliveryVariant
      )?.node;
      const saturdayNextDayVariant =
        product.shopifyProduct?.variants.edges.find(
          (v: { node: ShopifyVariantNode }) =>
            v.node.title === saturdayNextDayDeliveryVariant
        )?.node;
      const isSaturday = selectedDate?.getDay() === 6;
      let variantId: string;
      let variantTitle: string;
      const variantTitlePropIndex = lineItem.attributes?.findIndex(
        (prop) => prop.key === VARIANT_TITLE_PROP
      );
      const saturdayNextDayDeliveryPropIndex = lineItem.attributes?.findIndex(
        (prop) => prop.key === SATURDAY_NEXTDAY_DELIVERY_PROP
      );
      const saturdayDeliveryPropIndex = lineItem.attributes?.findIndex(
        (prop) => prop.key === SATURDAY_DELIVERY_PROP
      );
      const nextDayDeliveryPropIndex = lineItem.attributes?.findIndex(
        (prop) => prop.key === NEXTDAY_DELIVERY_PROP
      );

      const currentOneTimeVariant = getCurrentOneTimeVariant();
      if (product.hasVariant && currentOneTimeVariant) {
        const saturdayNextDayVariantSelected = variants?.find(function (e: {
          node: ShopifyVariantNode;
        }) {
          return (
            e.node.title.includes(saturdayNextDayDeliveryVariant) &&
            e.node.sku === currentOneTimeVariant?.value
          );
        })?.node;
        const nextDayVariantSelected = variants?.find(function (e: {
          node: ShopifyVariantNode;
        }) {
          return (
            e.node.title.includes(nextdayDeliveryVariant) &&
            e.node.sku === currentOneTimeVariant?.value
          );
        })?.node;
        const saturdayVariantSelected = variants?.find(function (e: {
          node: ShopifyVariantNode;
        }) {
          return (
            e.node.title.includes(saturdayDeliveryVariant) &&
            e.node.sku === currentOneTimeVariant?.value
          );
        })?.node;
        const regularVariantSelected = variants?.find(function (e: {
          node: ShopifyVariantNode;
        }) {
          return e.node.sku === currentOneTimeVariant?.value;
        })?.node;
        if (isNextDayDelivery && isSaturday && saturdayNextDayVariantSelected) {
          variantId = saturdayNextDayVariantSelected.id;
          variantTitle = saturdayNextDayVariantSelected.title;
        } else if (isNextDayDelivery && nextDayVariantSelected) {
          variantId = nextDayVariantSelected.id;
          variantTitle = nextDayVariantSelected.title;
        } else if (isSaturday && saturdayVariantSelected) {
          variantId = saturdayVariantSelected.id;
          variantTitle = saturdayVariantSelected.title;
        } else {
          variantId = regularVariantSelected.id;
          variantTitle = regularVariantSelected.title;
        }
      } else if (isNextDayDelivery && isSaturday && saturdayNextDayVariant) {
        variantId = saturdayNextDayVariant.id;
        variantTitle = saturdayNextDayVariant.title;
      } else if (isNextDayDelivery && nextDayVariant) {
        variantId = nextDayVariant.id;
        variantTitle = nextDayVariant.title;
      } else if (isSaturday && saturdayVariant) {
        variantId = saturdayVariant.id;
        variantTitle = saturdayVariant.title;
      } else {
        variantId = defaultVariant.id;
        variantTitle = defaultVariant.title;
      }
      if (variantTitlePropIndex > -1) {
        newProps[variantTitlePropIndex] = {
          ...newProps[variantTitlePropIndex],
          value: variantTitle,
        };
      }
      if (saturdayNextDayDeliveryPropIndex > -1) {
        newProps[saturdayNextDayDeliveryPropIndex] = {
          ...newProps[saturdayNextDayDeliveryPropIndex],
          value:
            isNextDayDeliverySurcharge && saturdayNextDaySurcharge && isSaturday
              ? "true"
              : "false",
        };
      } else {
        newProps.push({
          key: SATURDAY_NEXTDAY_DELIVERY_PROP,
          value:
            isNextDayDeliverySurcharge && saturdayNextDaySurcharge && isSaturday
              ? "true"
              : "false",
        });
      }
      if (saturdayDeliveryPropIndex > -1) {
        newProps[saturdayDeliveryPropIndex] = {
          ...newProps[saturdayDeliveryPropIndex],
          value:
            isSaturday && saturdaySurcharge && !isNextDayDelivery
              ? "true"
              : "false",
        };
      } else {
        newProps.push({
          key: SATURDAY_DELIVERY_PROP,
          value:
            isSaturday && saturdaySurcharge && !isNextDayDelivery
              ? "true"
              : "false",
        });
      }
      if (nextDayDeliveryPropIndex > -1) {
        newProps[nextDayDeliveryPropIndex] = {
          ...newProps[nextDayDeliveryPropIndex],
          value:
            isNextDayDeliverySurcharge && nextDaySurcharge && !isSaturday
              ? "true"
              : "false",
        };
      } else {
        newProps.push({
          key: NEXTDAY_DELIVERY_PROP,
          value:
            isNextDayDeliverySurcharge && nextDaySurcharge && !isSaturday
              ? "true"
              : "false",
        });
      }
      variantId = variantId.includes("gid://shopify")
        ? variantId
        : b64decode(variantId);
      if (!isSubscription) {
        return cartOperations.editItem([
          produce(lineItem, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft.attributes = newProps;
            if (draft.merchandise) {
              // eslint-disable-next-line no-param-reassign
              draft.merchandise.id = variantId;
            }
          }),
        ]);
      }
      return cartOperations.editItem([
        produce(lineItem, (draft) => {
          // eslint-disable-next-line no-param-reassign
          draft.attributes = newProps;
        }),
      ]);
    }
  };
  return (
    <div css={[tw`rounded bg-white relative`]}>
      {deliveryDateValue ? (
        <div css={tw`flex text-gray-700`}>
          <div css={[{ width: isInDrawer ? "100%" : 350 }]}>
            <div css={tw`mt-1 text-sm`}>
              {isWeeklySuperGiftProduct(product.id) &&
              deliveryDate &&
              secondDeliveryDate &&
              thirdDeliveryDate ? (
                <>
                  <div>
                    <span
                      css={tw`font-bold`}
                    >{`${FIRST_DELIVERY_DATE_LABEL}: `}</span>
                    <span>{format(deliveryDate, "PPP")}</span>
                  </div>
                  <div>
                    <span
                      css={tw`font-bold`}
                    >{`${ESTIMATED_DELIVERY_DATE_LABEL}: `}</span>
                    <p>{format(secondDeliveryDate, "PPP")}</p>{" "}
                    <p>{format(thirdDeliveryDate, "PPP")}</p>
                  </div>
                </>
              ) : (
                <DeliveryDatePicker
                  label={DELIVERY_DATE_LABEL}
                  product={product}
                  onChange={updateDeliveryDate}
                  initialDate={parse(
                    deliveryDateValue,
                    "MM-dd-yyyy",
                    new Date()
                  )}
                  openSlideCalendar={openSlideCalendar}
                  handleOpenOrCloseSlideCalendar={
                    handleOpenOrCloseSlideCalendar
                  }
                  variant={variant}
                />
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
