/* eslint-disable react/function-component-definition */
import React, { FC } from "react";
import tw from "twin.macro";
import { VariantFrequency } from "../Cart/utils";
import { copy } from "./constants";
import { StyledTooltip } from "./styled";

type Props = {
  hasSalePrice?: boolean;
  recurringFrequency: string;
  variantFrequency?: VariantFrequency;
};
export const ProductSubscriptionTooltips: FC<Props> = ({
  hasSalePrice,
  recurringFrequency,
  variantFrequency,
}) => {
  return (
    <div css={tw`w-full`}>
      <StyledTooltip
        arrow
        disableFocusListener
        enterTouchDelay={0}
        leaveTouchDelay={4000}
        placement="bottom-start"
        title={
          <div>
            <p>This subscription will automatically renew: </p>
            <ul css={tw`list-disc pl-5`}>
              <li>
                Delivery schedule:{" "}
                <span css={tw`capitalize`}>{`${recurringFrequency}`}</span>
              </li>
              <li>
                {`Payment: Every ${variantFrequency?.chargeInterval} ${variantFrequency?.unitType}`}
                {(variantFrequency?.chargeInterval ?? 0) > 1 ? "s" : null}
              </li>
            </ul>
            {hasSalePrice && (
              <p css={tw`text-xs italic mt-2`}>
                Subscription will renew at regular price after initial payment.
              </p>
            )}
          </div>
        }
      >
        <div>
          <span
            css={tw`text-xs underline underline-offset-4 inline-block align-middle cursor-pointer py-1 mt-1 mb-1`}
          >
            {copy.autoRenewTitle}
          </span>
        </div>
      </StyledTooltip>
      <StyledTooltip
        arrow
        disableFocusListener
        enterTouchDelay={0}
        leaveTouchDelay={8000}
        placement="bottom-start"
        title={
          <div>
            <p>{copy.howToCancelText}</p>
          </div>
        }
      >
        <div>
          <span
            css={tw`text-xs underline underline-offset-4 inline-block align-middle cursor-pointer py-1`}
          >
            {copy.howToCancelTitle}
          </span>
        </div>
      </StyledTooltip>
    </div>
  );
};
