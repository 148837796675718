import { useQuery } from "react-query";
import wretch from "wretch";

export const useVerifyZipCode = (
  zipcode: string,
  isResidential: boolean,
  deliveryDate: string
) => {
  return useQuery<{ valid: boolean } | undefined, Error>(
    ["verifyZipCode", { zipcode, isResidential }],
    async () => {
      const response = await wretch("/api/shipping")
        .content("application/json")
        .accept("application/json")
        .post({ zipcode, isResidential, deliveryDate });
      return response.json();
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      cacheTime: 0,
    }
  );
};
