/* eslint-disable no-console */
import { FC, useEffect, useMemo, useState } from "react";
import { getUpsellingProducts } from "src/features/Cart/CartDrawer/GetUpSellingProduct";
import tw from "twin.macro";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import { ShopifyCartData } from "src/shared/types/shopify";
import { UpsellingSectionMobile } from "src/features/ProductCard/UpsellingSectionMobile";
import { useCheckoutData } from "../state";
import {
  CMS_PRODUCT_ID_PROP,
  IS_NEXT_DAY_PRODUCT_PROP,
  ONE_TIME_VARIANT_EXCLUDED_IDS_PROP,
  ONE_TIME_VARIANT_ID_PROP,
} from "../constants";

type Props = {
  cartData: ShopifyCartData;
  variant?: "full" | "drawer";
};

// eslint-disable-next-line react/function-component-definition
export const UpSellingProducts: FC<Props> = ({
  cartData,
  variant = "full",
}) => {
  const [, cmsCartDataResult] = useCheckoutData();
  const { data } = cmsCartDataResult;

  const [upSellingProduct, setUpSellingproduct] =
    useState<ProductFieldsFragment[]>();

  const getUpsellingToRender = (usProducts: ProductFieldsFragment[]) => {
    const cartItems = cartData?.cart.lines.edges?.map(
      (p) => p.node.merchandise?.product?.handle
    );
    const upsellingProductInCart = upSellingProduct
      ?.filter((up) => cartItems?.includes(up.shopifyProduct.handle))
      .map((p) => p.listUpsellingToExclude)
      .flat();
    const finalUpsellingToRender = usProducts.filter((p) => {
      const upsellingProductInCartIds = upsellingProductInCart?.map(
        (up) => up.id
      );
      return !upsellingProductInCartIds?.includes(p.id);
    });
    return finalUpsellingToRender ?? [];
  };
  const getIsThereNextDayProductInTheCart = () => {
    return cartData?.cart.lines.edges?.some((p) =>
      p.node.attributes?.some(
        (attr) =>
          attr.key === IS_NEXT_DAY_PRODUCT_PROP &&
          attr.value?.toLocaleLowerCase() === "true"
      )
    );
  };
  const getUpsellingProductsAvailableForIPDProducts = () => {
    let filterListUpSellingProduct =
      upSellingProduct
        ?.filter((product) => {
          const cartItems = cartData?.cart.lines.edges?.map(
            (p) => p.node.merchandise?.product?.handle
          );
          const upsellingItems = product?.upsellingProducts
            ?.map((p) => {
              if (
                p.shopifySubscriptionProducts &&
                p.shopifySubscriptionProducts.length > 0
              ) {
                const handles = p.shopifySubscriptionProducts.map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (sp: any) => sp.handle
                );
                return handles;
              }
              return p.shopifyProduct.handle;
            })
            .flat();
          const hasUpsellingProduct = cartItems?.some(
            (r) => upsellingItems?.indexOf(r) >= 0
          );
          return (
            (product.upsellingProducts.length === 0 &&
              !product.isAvailableForNextday) ||
            hasUpsellingProduct
          );
        })
        ?.filter((product) => {
          const productIds = data?.allProducts.map((p) => p.id);
          return !productIds?.includes(product.id);
        }) ?? [];

    return filterListUpSellingProduct;
  };
  const getUpsellingNextDayProductsForExclude = () => {
    const listUpsellingNextDayProductsExclude: string[] = [];

    // list next day products added to the cart
    const listNextDayProductsNotUpsellingInCart =
      cartData?.cart.lines.edges
        ?.filter((p) =>
          p.node.attributes?.some(
            (attr) =>
              attr.key === IS_NEXT_DAY_PRODUCT_PROP &&
              attr.value?.toLocaleLowerCase() === "true"
          )
        )
        .map(
          (p) =>
            p.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
              ?.value ?? ""
        ) ?? [];

    // list of next day products that will be excluded for some upselling products
    const listNextDayProductToExclude =
      upSellingProduct
        ?.filter(
          (up) =>
            up.isAvailableForNextday && up.excludeNextdayProducts?.length > 0
        )
        .map((up) => up.excludeNextdayProducts)
        .flat()
        .filter((pr) =>
          listNextDayProductsNotUpsellingInCart.some((pid) => pid === pr.id)
        ) ?? [];

    // check if there are any next day product in the cart that are not excluded for some upselling product
    const isThereNextDayProductInTheCartNotExcluded =
      listNextDayProductsNotUpsellingInCart.some(
        (pid) => !listNextDayProductToExclude?.some((pex) => pex.id === pid)
      );

    // if there are any next day product in the cart that are not excluded for some upselling product, no upselling product is excluded
    if (isThereNextDayProductInTheCartNotExcluded)
      return listUpsellingNextDayProductsExclude;

    const listUpsellingNextDayProductsToExclude =
      upSellingProduct
        ?.filter(
          (up: ProductFieldsFragment) =>
            up.isAvailableForNextday &&
            up.excludeNextdayProducts?.length > 0 &&
            up.excludeNextdayProducts?.some((pex) =>
              listNextDayProductToExclude?.some((pr) => pr.id === pex.id)
            )
        )
        ?.map((m: ProductFieldsFragment) => m.id ?? "") ?? [];

    if ((listNextDayProductToExclude?.length ?? 0) > 0) {
      listUpsellingNextDayProductsToExclude.forEach((upid) => {
        const isExludeInAllProductInTheCart = listNextDayProductToExclude.every(
          (p) => {
            return upSellingProduct?.some(
              (up: ProductFieldsFragment) =>
                up.isAvailableForNextday &&
                up.excludeNextdayProducts?.length > 0 &&
                up.id === upid &&
                up.excludeNextdayProducts?.some((pex) => pex.id === p.id)
            );
          }
        );
        if (
          isExludeInAllProductInTheCart &&
          listNextDayProductToExclude.length > 0
        )
          listUpsellingNextDayProductsExclude.push(upid);
      });
    }

    // get upselling IPD products
    const listUpsellingProductsAvaibleForIPD =
      getUpsellingProductsAvailableForIPDProducts();

    // Upselling products that have to be displayed with the IPD products are not excluded
    return listUpsellingNextDayProductsExclude.filter(
      (pid) => !listUpsellingProductsAvaibleForIPD.some((pp) => pp.id === pid)
    );
  };
  const getUpsellingProductsAvailableForAllNextDayProducts = (
    listUpsellingProductsAvaibleForIPD: ProductFieldsFragment[]
  ) => {
    if (getIsThereNextDayProductInTheCart()) {
      const listUpsellingNextDayProductForExclude =
        getUpsellingNextDayProductsForExclude();
      const listUpsellingProductsForNextDay =
        upSellingProduct?.filter(
          (up) =>
            up.isAvailableForNextday &&
            !listUpsellingProductsAvaibleForIPD.some((p) => p.id === up.id) &&
            !data?.allProducts.some((p) => p.id === up.id) &&
            !listUpsellingNextDayProductForExclude.includes(up.id)
        ) ?? [];
      return listUpsellingProductsForNextDay;
    }
    return [];
  };
  const getAdditionalUpsellingProductsNextDayExcludePerVariant = (
    resultUpsellingProducts: ProductFieldsFragment[],
    newCheckout: ShopifyCartData
  ) => {
    let listUpsellingProductsAdd: string[];
    let removeAdditionalUpsellingProducts: string[] = [];

    listUpsellingProductsAdd = resultUpsellingProducts.map((m) => m.id);

    // list product with variants added to the cart
    const newCheckoutIds =
      newCheckout?.cart.lines.edges
        ?.filter((p) =>
          p.node.attributes?.some(
            (attr) =>
              attr.key === ONE_TIME_VARIANT_ID_PROP && attr.value.length > 0
          )
        )
        .map(
          (p) =>
            p.node.attributes?.find((a) => a.key === ONE_TIME_VARIANT_ID_PROP)
              ?.value ?? ""
        ) ?? [];

    if (newCheckoutIds.length === 0) return listUpsellingProductsAdd;

    // string exlude upselling product per variants added to the cart
    const excludeUpsellingProductPerVariant =
      newCheckout?.cart.lines.edges
        ?.filter((p) =>
          p.node.attributes?.some(
            (attr) =>
              attr.key === ONE_TIME_VARIANT_EXCLUDED_IDS_PROP &&
              attr.value.length > 0
          )
        )
        .map((p) => {
          return {
            productId:
              p.node.attributes?.find((a) => a.key === CMS_PRODUCT_ID_PROP)
                ?.value ?? "",
            listToExlude: (
              p.node.attributes?.find(
                (a) => a.key === ONE_TIME_VARIANT_EXCLUDED_IDS_PROP
              )?.value ?? ""
            ).split(","),
          };
        }) ?? [];

    if (excludeUpsellingProductPerVariant.length === 0)
      return listUpsellingProductsAdd;

    listUpsellingProductsAdd.forEach((idup) => {
      const isExcludeForEveryProduct = excludeUpsellingProductPerVariant.every(
        (lpex) =>
          lpex.listToExlude.some((idp) => idp === idup) &&
          lpex.listToExlude.length > 0
      );

      if (
        isExcludeForEveryProduct &&
        (newCheckout?.cart.lines.edges?.length ?? 0) ===
          excludeUpsellingProductPerVariant.length &&
        excludeUpsellingProductPerVariant.length > 0 &&
        !removeAdditionalUpsellingProducts.some((id) => id === idup)
      ) {
        removeAdditionalUpsellingProducts.push(idup);
      } else if (
        !removeAdditionalUpsellingProducts.some((id) => id === idup) &&
        (newCheckout?.cart.lines.edges?.length ?? 0) > 0
      ) {
        let listToProductToExcludePerVariant: string[] = [];
        let isProductInTheCart = true;

        listToProductToExcludePerVariant = excludeUpsellingProductPerVariant
          .filter((f) => f.listToExlude.includes(idup))
          .map((m) => m.productId);

        const upselling = resultUpsellingProducts.find(
          (f) => f.isUpselling && f.id === idup
        );

        if (
          upselling &&
          !upselling.isAvailableForNextday &&
          upselling.upsellingProducts.length > 0
        ) {
          const listIPDProducts = upselling.upsellingProducts.map((m) => m.id);
          isProductInTheCart =
            newCheckout?.cart.lines.edges?.some((p) =>
              p.node.attributes?.some(
                (attr) =>
                  attr.key === CMS_PRODUCT_ID_PROP &&
                  listIPDProducts.includes(attr.value) &&
                  !listToProductToExcludePerVariant.includes(attr.value)
              )
            ) ?? false;
        } else if (upselling && upselling.isAvailableForNextday) {
          const lisNextDayProductsExclude =
            upselling.excludeNextdayProducts.map((m) => m.id);
          isProductInTheCart =
            newCheckout?.cart.lines.edges?.some(
              (p) =>
                p.node.attributes?.some(
                  (attr) =>
                    attr.key === IS_NEXT_DAY_PRODUCT_PROP &&
                    attr.value?.toLocaleLowerCase() === "true"
                ) &&
                p.node.attributes?.some(
                  (attr) =>
                    attr.key === CMS_PRODUCT_ID_PROP &&
                    !listToProductToExcludePerVariant.includes(attr.value) &&
                    !lisNextDayProductsExclude.includes(attr.value)
                )
            ) ?? false;
        }

        if (upselling && !isProductInTheCart)
          removeAdditionalUpsellingProducts.push(idup);
      }
    });

    return listUpsellingProductsAdd.filter(
      (idp) => !removeAdditionalUpsellingProducts.includes(idp)
    );
  };
  const orderUpsellingPerPriority = (
    a: ProductFieldsFragment,
    b: ProductFieldsFragment
  ) => {
    if (
      (a.priorityUpselling ?? Number.MAX_SAFE_INTEGER) <
      (b.priorityUpselling ?? Number.MAX_SAFE_INTEGER)
    ) {
      return -1;
    }
    if (
      (a.priorityUpselling ?? Number.MAX_SAFE_INTEGER) >
      (b.priorityUpselling ?? Number.MAX_SAFE_INTEGER)
    ) {
      return 1;
    }
    return 0;
  };

  // load upselling products
  useEffect(() => {
    getUpsellingProducts(false).then((usProducts: ProductFieldsFragment[]) => {
      if (usProducts) setUpSellingproduct(usProducts);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const finalUpsellingList = useMemo(() => {
    const listUpsellingProductsAvaibleForIPD =
      getUpsellingProductsAvailableForIPDProducts();
    const listUpsellingProductsAvaibleForAllNextDay =
      getUpsellingProductsAvailableForAllNextDayProducts(
        listUpsellingProductsAvaibleForIPD
      );
    const listUpsellingNextDayProductExclude =
      getUpsellingNextDayProductsForExclude();

    let resultUpsellingProducts = [
      ...listUpsellingProductsAvaibleForIPD,
      ...listUpsellingProductsAvaibleForAllNextDay,
    ];

    resultUpsellingProducts = getUpsellingToRender(resultUpsellingProducts);

    const resultProducts = resultUpsellingProducts.filter(
      (p) => !listUpsellingNextDayProductExclude.includes(p.id)
    );

    const excludedUpsellingVariant =
      getAdditionalUpsellingProductsNextDayExcludePerVariant(
        resultProducts,
        cartData
      );

    return resultProducts
      .filter((p) => excludedUpsellingVariant.includes(p.id))
      .sort(orderUpsellingPerPriority);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.allProducts, upSellingProduct]);

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {finalUpsellingList && finalUpsellingList?.length > 0 && (
        <div css={tw`pl-5 mt-7 mb-5`}>
          <div
            css={[
              tw`font-roboto text-sm font-bold tracking-wider leading-5 mt-4`,
            ]}
          >
            MAKE YOUR GIFT PERFECT
          </div>

          <div css={tw`mt-1`}>
            {finalUpsellingList && (
              <UpsellingSectionMobile
                usProducts={finalUpsellingList}
                inProductCard={variant === "full"}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
