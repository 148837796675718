import tw, { styled } from "twin.macro";

type ContentSectionProps = {
  bgColor?: string;
  sectionPadded?: boolean;
};
export const ContentSection = styled.section(
  ({ bgColor = "white", sectionPadded = true }: ContentSectionProps) => [
    sectionPadded ? tw`py-10 xl:py-[50px]` : tw`py-0`,
    bgColor === "white" ? tw`bg-white` : tw`bg-bloomsy-lightgray`,
  ]
);
export const ContentSectionText = styled.div([
  tw`text-center font-roboto max-w-3xl mx-auto px-6 lg:px-0`,
  [{ color: "#374151" }],
  { "&>p": tw`mb-4` },
]);
export const ContentSectionTitle = styled.h1([
  tw`text-3xl sm:text-4xl font-roboto leading-tight px-4 sm:px-0`,
]);
export const ContentSectionTitleH2 = styled.h2([
  tw`text-3xl sm:text-4xl font-roboto leading-10 px-4 sm:px-0 text-black`,
]);
export const ContentSectionTitleH3 = styled.h3([
  tw`text-2xl sm:text-4xl font-roboto leading-tight px-4 sm:px-0`,
]);
export const ContentSectionSubtitle = styled.p([
  tw`text-sm uppercase tracking-wider font-bold font-roboto px-4 sm:px-0`
]);
