/* eslint-disable react/function-component-definition */
import React, { FC, Fragment } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { ProductFieldsCartFragment } from "src/generated/datocms-types";
import { CartItemQuantity } from "src/features/Cart/CartPage/CartItemQuantity";
import { IconButton } from "src/shared/components/IconButton";
import {
  cartOperations,
  useProductForm,
  useInvalidDeliveryDates,
} from "src/features/Cart/state";
import { FiXCircle } from "react-icons/fi";
import { formatPrice } from "src/shared/helpers";
import { useMediaQuery } from "@mui/material";
import {
  getProductRecurringLabel,
  isSuperGiftProduct,
  isWeeklySuperGiftProduct,
} from "src/shared/utils/products";
import { getVariantFrequency } from "src/features/Cart/utils";
import { SuperGiftCartSummary } from "src/features/SuperGift/SuperGiftCartSummary";
import { ProductGiftMessageForm } from "src/features/ProductForm/ProductGiftMessageForm";
import { ProductSubscriptionTooltips } from "src/features/ProductForm/ProductSubscriptionTooltips";
import {
  DELIVERY_DATE_PROP,
  ONE_TIME_VARIANT_PROP,
  ONE_TIME_VARIANT_ID_PROP,
  VIRTUAL_ADD_ON,
} from "src/features/Cart/constants";
import { getNameValueProp } from "src/shared/utils";
import { formatDate, timezoneDate } from "src/shared/utils/dates";
import { parse } from "date-fns";
import { IMAGE_QUALITY } from "src/constants";
import { ShopifyLineItem, ShopifyVariantNode } from "src/shared/types/shopify";
import { DeliveryDate } from "./DeliveryDate";

type Props = {
  lineItem: ShopifyLineItem;
  product: ProductFieldsCartFragment;
  variant?: "full" | "drawer";
};
export const CartItemCard: FC<Props> = ({
  lineItem,
  product,
  variant = "full",
}) => {
  const isSuperGift = isSuperGiftProduct(product.id);
  const isInDrawer = variant === "drawer";
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const {
    isGift,
    setIsGift,
    giftEmail,
    setGiftEmail,
    giftMessage,
    setGiftMessage,
    setIsGiftMessageFormDirty,
    isAutoRenew,
    setIsAutoRenew,
    recipientName,
    setRecipientName,
  } = useProductForm({ product });
  const recurringFrequency = getProductRecurringLabel(
    product.title,
    product.tags
  );
  const { removeInvalidDeliveryDate } = useInvalidDeliveryDates();
  const variantFrequency = getVariantFrequency(
    lineItem.merchandise?.title ?? ""
  );
  const productTitle = product.title;

  const oneTimeVariantIdPropValue = getNameValueProp(
    lineItem.attributes ?? [],
    ONE_TIME_VARIANT_ID_PROP
  );
  const variantGallery = product.variants.find(
    (v) => v.id === oneTimeVariantIdPropValue
  );
  const hasVariantImage = !!variantGallery;
  const image = hasVariantImage
    ? variantGallery?.gallery[0]
    : product.gallery[0];
  const productImageSize = { w: isInDrawer || !isDesktop ? 50 : 150 };
  const productImageSrc = `${image.url}?w=${productImageSize.w}&h=${productImageSize.w}&fit=crop`;
  const isSub = !!lineItem.sellingPlanAllocation;
  const variantTitle = isSub ? lineItem.merchandise?.title : null;
  const hasSalePrice =
    Number(
      product?.shopifyProduct?.compareAtPriceRange?.minVariantPrice.amount
    ) > 0;
  const isPrepay = variantFrequency
    ? (variantFrequency.chargeInterval ?? 1) /
        (variantFrequency.orderInterval ?? 1) >
      1
    : false;
  const productTypeLabelFrequency = isPrepay ? "Prepaid" : recurringFrequency;
  const productTypeLabel = isSub
    ? `${productTypeLabelFrequency} Subscription`
    : null;
  const productPrice = (
    <span css={tw`font-roboto`}>
      {lineItem.cost?.amountPerQuantity.amount
        ? formatPrice(+lineItem.cost.amountPerQuantity.amount)
        : "0"}
    </span>
  );
  const isFreeProduct = productPrice.props.children === "$0.00";
  const productPriceString = isFreeProduct ? "FREE" : productPrice;
  const isUpsellingProduct = product.isUpselling;
  const handleRemove = () => {
    cartOperations.removeItem(lineItem);
    removeInvalidDeliveryDate(lineItem);
  };
  const closeButton = (
    <IconButton onClick={() => handleRemove()} size="large">
      <FiXCircle css={tw`text-gray-500`} />
    </IconButton>
  );
  const variantProduct: ShopifyVariantNode =
    product.shopifyProduct?.variants.edges[0].node;
  const isVirtualProduct = variantProduct.sku === VIRTUAL_ADD_ON;
  const productQty =
    !isSub && !isVirtualProduct && !isFreeProduct ? (
      <CartItemQuantity lineItem={lineItem} />
    ) : null;
  const deliveryDateProp = getNameValueProp(
    lineItem.attributes ?? [],
    DELIVERY_DATE_PROP
  );
  const deliveryDate = deliveryDateProp
    ? formatDate(
        timezoneDate(parse(deliveryDateProp, "MM-dd-yyyy", new Date()) || ""),
        "MMM dd, yyyy"
      )
    : null;
  const oneTimeVariantPropValue = getNameValueProp(
    lineItem.attributes ?? [],
    ONE_TIME_VARIANT_PROP
  );
  const [isSaturday, setIsSaturday] = React.useState(false);
  React.useEffect(() => {
    if (deliveryDateProp) {
      const weekday = new Date(
        parseInt(deliveryDateProp.split("-")[2], 10),
        parseInt(deliveryDateProp.split("-")[0], 10) - 1,
        parseInt(deliveryDateProp.split("-")[1], 10)
      ).getUTCDay();
      if (weekday === 6) {
        setIsSaturday(true);
      } else {
        setIsSaturday(false);
      }
    }
  }, [deliveryDateProp]);
  return (
    <div
      css={[
        tw`rounded bg-white p-3 relative`,
        isInDrawer ? tw`mb-4` : tw`mb-6`,
        {
          boxShadow: `0 3px 15px 3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)`,
        },
      ]}
    >
      <div css={tw` pb-2 flex items-center text-gray-700`}>
        {image && (
          <div
            css={[
              tw`relative flex-shrink-0`,
              { width: productImageSize.w, height: productImageSize.w },
            ]}
          >
            <Image
              css={tw`object-cover`}
              src={productImageSrc}
              layout="fill"
              quality={IMAGE_QUALITY}
              alt={image.alt ?? ""}
            />
          </div>
        )}
        <div css={[{ width: isInDrawer ? "auto" : 300 }, tw`ml-2`]}>
          <div css={tw`text-black`}>
            <div css={tw`font-bold leading-tight`}>{productTitle}</div>
            {productTypeLabel &&
              !isWeeklySuperGiftProduct(product.id) &&
              !isSuperGift && (
                <div css={tw`text-xs capitalize`}>{productTypeLabel}</div>
              )}
          </div>
        </div>
        {!isInDrawer && isDesktop && (
          <Fragment>
            <div css={[{ width: 110 }, tw`ml-auto`]}>{productQty}</div>
            <div css={tw`ml-auto text-lg text-center`}>
              <span css={tw`block`}>{productPrice}</span>
              <span css={tw`block text-xs`}>{variantTitle}</span>
            </div>
            <div css={tw`ml-auto`}>{closeButton}</div>
          </Fragment>
        )}
      </div>
      {oneTimeVariantPropValue && (
        <div css={tw`text-sm text-left mt-4 border-b pb-2`}>
          <span css={tw`block`}>{oneTimeVariantPropValue}</span>
        </div>
      )}
      {!isUpsellingProduct ? (
        <div css={[tw`text-sm py-2`]}>
          <DeliveryDate
            key={`delivery_date_${product.id}`}
            lineItem={lineItem}
            product={product}
            variant={variant}
          />
        </div>
      ) : (
        <div css={[tw`text-sm py-2 border-b`]}>
          {isUpsellingProduct && <span>Estimated </span>}Delivery Date:{" "}
          {deliveryDate}
        </div>
      )}
      {!isSuperGift && !isUpsellingProduct && (
        <div>
          <ProductGiftMessageForm
            product={product}
            lineItem={lineItem}
            isGift={isGift}
            setIsGift={setIsGift}
            giftEmail={giftEmail}
            setGiftEmail={setGiftEmail}
            giftMessage={giftMessage}
            setGiftMessage={setGiftMessage}
            setIsGiftMessageFormDirty={setIsGiftMessageFormDirty}
            isAutoRenew={isAutoRenew}
            setIsAutoRenew={setIsAutoRenew}
            isPrepay={isPrepay}
            isSuperGift={isSuperGift}
            recipientName={recipientName}
            setRecipientName={setRecipientName}
          />
        </div>
      )}
      {isSuperGift && (
        <div>
          <SuperGiftCartSummary lineItem={lineItem} />
        </div>
      )}
      {(isInDrawer || !isDesktop) && (
        <div>
          {!isWeeklySuperGiftProduct(product.id) &&
          ((isPrepay &&  !isSuperGift) ||
            (isSub && !isPrepay)) ? (
            <div css={tw`flex rounded p-2 my-2`}>
              <ProductSubscriptionTooltips
                hasSalePrice={hasSalePrice}
                recurringFrequency={recurringFrequency}
                variantFrequency={variantFrequency}
              />
            </div>
          ) : null}
          <div css={tw`pt-2 flex items-center justify-between`}>
            <div css={tw`text-left`}>
              <span css={tw`block font-bold text-lg`}>
                {productPriceString}
              </span>
              {!isSuperGift && (
                <span css={tw`block text-xs`}>{variantTitle}</span>
              )}
            </div>
            {productQty && <div>{productQty}</div>}
            <div>{closeButton}</div>
          </div>
        </div>
      )}
      {!isInDrawer &&
      isDesktop &&
      !isWeeklySuperGiftProduct(product.id) &&
      ((isPrepay && !isSuperGift) ||
        (isSub && !isPrepay)) ? (
        <ProductSubscriptionTooltips
          hasSalePrice={hasSalePrice}
          recurringFrequency={recurringFrequency}
          variantFrequency={variantFrequency}
        />
      ) : null}
    </div>
  );
};
