/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import { FC, Fragment, useEffect, useState } from "react";
import { Drawer } from "src/shared/components/Drawer";
import { IconButton } from "src/shared/components/IconButton";
import tw, { styled } from "twin.macro";
import { IoClose } from "react-icons/io5";
import {
  useCheckoutData,
  useCartVisibility,
  useCartBusy,
} from "src/features/Cart/state";
import { CartItems } from "src/features/Cart/CartPage/CartItems";
import { CartSummary } from "src/features/Cart/CartPage/CartSummary";
import { CartEmpty } from "src/features/Cart/CartPage/CartEmpty";
import BloomsyLoader from "src/shared/components/BloomsyLoader";
import { dataLayer } from "src/features/Analytics";

const StyledDrawer = styled(Drawer)([
  { ".MuiDrawer-paper": tw`w-full sm:w-400 overflow-x-hidden` },
]);

export const CartDrawer: FC = () => {
  const [isOpen, toggleCart] = useCartVisibility();
  const [cartData, cmsCartDataResult] = useCheckoutData();
  const { data, loading } = cmsCartDataResult;
  const cartIsBusy = useCartBusy();
  const isLoading = loading || cartIsBusy;
  const [openSlideCalendar, setOpenSlideCalendar] = useState("");

  const handleOpenOrCloseSlideCalendar = (productId: string) => {
    setOpenSlideCalendar(productId);
  };

  useEffect(() => {
    if (!isOpen) {
      setOpenSlideCalendar("");
      return;
    }
    dataLayer({
      event: "viewCartDrawer",
    });
  }, [isOpen]);

  return (
    <StyledDrawer open={isOpen} onClose={toggleCart} anchor="right">
      <div css={[tw`relative`]}>
        <div
          css={[
            tw`px-4 py-2 flex items-center`,
            { minWidth: 300 },
            tw`border-b border-[#D2D5DB] px-5 py-0 pt-2`,
          ]}
        >
          <div
            css={[
              tw`font-bold font-roboto uppercase tracking-wider`,
              tw`mr-auto text-sm leading-[48px] tracking-wider`,
            ]}
          >
            <span>My Cart</span>
          </div>
          <IconButton onClick={toggleCart} size="large">
            <IoClose size={25} />
          </IconButton>
        </div>

        {cartData && data ? (
          <div css={[tw`px-0`]}>
            <CartItems
              variant="drawer"
              products={data}
              cartData={cartData}
              handleOpenOrCloseSlideCalendar={handleOpenOrCloseSlideCalendar}
              openSlideCalendar={openSlideCalendar}
            />
            <CartSummary variant="drawer" cartData={cartData} />
          </div>
        ) : (
          <Fragment>
            {isLoading ? (
              <div
                css={[tw`flex items-center justify-center`, { height: 200 }]}
              >
                <div css={tw`text-center`}>
                  <BloomsyLoader css={tw`text-bloomsy-red w-8 h-8`} />
                  <span css={tw`block mt-2 text-sm text-gray-700`}>
                    Loading cart...
                  </span>
                </div>
              </div>
            ) : (
              <CartEmpty variant="drawer" />
            )}
          </Fragment>
        )}
      </div>
    </StyledDrawer>
  );
};
