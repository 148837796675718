import { FC } from "react";
import { cartOperations } from "src/features/Cart/state";
import { produce } from "immer";
import { ProductQuantityInput } from "src/features/ProductForm/ProductQuantityInput";
import { ShopifyLineItem } from "src/shared/types/shopify";

type Props = {
  lineItem: ShopifyLineItem;
};
// eslint-disable-next-line react/function-component-definition
export const CartItemQuantity: FC<Props> = ({ lineItem }) => {
  const increase = () => {
    return cartOperations.editItem([
      produce(lineItem, (draft) => {
        if (!draft.quantity) {
          // eslint-disable-next-line no-param-reassign
          draft.quantity = 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.quantity += 1;
        }
      }),
    ]);
  };
  const decrease = () => {
    return cartOperations.editItem([
      produce(lineItem, (draft) => {
        if (!draft.quantity) {
          // eslint-disable-next-line no-param-reassign
          draft.quantity = 1;
        } else {
          // eslint-disable-next-line no-param-reassign
          draft.quantity -= 1;
        }
      }),
    ]);
  };
  const { quantity } = lineItem;
  return (
    <ProductQuantityInput
      quantity={quantity ?? 1}
      onIncrease={increase}
      onDecrease={decrease}
    />
  );
};
