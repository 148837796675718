/* eslint-disable react/function-component-definition */
import { FC } from "react";
import {
  IconButton as MuiIconButton,
  createTheme,
  ThemeProvider,
  StyledEngineProvider,
} from "@mui/material";
import { IconButtonProps as MuiIconButtonProps } from "@mui/material/IconButton";
import { styled } from "twin.macro";

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          paddingLeft: "0px",
          padding: "0px !important",
        },
      },
    },
  },
});
const StyledIconButton = styled(MuiIconButton)``;
interface IconButtonProps extends MuiIconButtonProps {}
export const IconButton: FC<IconButtonProps> = (props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledIconButton disableRipple {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
