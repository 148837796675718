import { FC } from "react";
import tw from "twin.macro";
import { IconButton } from "src/shared/components/IconButton";
import { FiMinus, FiPlus } from "react-icons/fi";

type Props = {
  quantity: number;
  onIncrease: () => void;
  onDecrease: () => void;
};
// eslint-disable-next-line react/function-component-definition
export const ProductQuantityInput: FC<Props> = ({
  quantity,
  onIncrease,
  onDecrease,
}) => {
  return (
    <div
      css={tw`flex items-center justify-center border px-2 py-1 rounded border-gray-300 w-auto h-[50px]`}
    >
      <IconButton onClick={onDecrease} disabled={quantity === 1} size="small">
        <FiMinus />
      </IconButton>
      <div css={tw`mx-4 text-center`}>
        {/* <div css={tw`uppercase tracking-wider text-xs`}>qty</div> */}
        <div css={tw`font-bold`}>{quantity}</div>
      </div>
      <IconButton onClick={onIncrease} size="small">
        <FiPlus />
      </IconButton>
    </div>
  );
};
