/* eslint-disable react/function-component-definition */
import { useEffect, useState } from "react";
import { Button } from "src/shared/components/Button";
import { useMediaQuery, Dialog, DialogContent } from "@mui/material";
import { ContentSectionTitle } from "src/shared/components/ContentSection";
import { MdClose } from "react-icons/md";
import { IconButton } from "src/shared/components/IconButton";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import {
  ShopifyLineItem,
  ShopifyMoneyV2,
  ShopifyProductJson,
  ShopifyVariantNode,
} from "src/shared/types/shopify";
import { formatShopifyPrice } from "src/shared/helpers";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import { copy } from "src/features/ProductForm/constants";
import { AiFillCheckCircle } from "react-icons/ai";
import {
  useCartBusy,
  useCheckoutData,
  useProductExistInCart,
  useProductForm,
  useCurrentUpsellingList,
  useCurrentSubTotal,
} from "src/features/Cart/state";
import { useQuery } from "react-query";
import { format } from "date-fns";
import {
  DatePickerClientConfigT,
  loadDatePickerConfig,
} from "src/features/ProductForm/utils";
import { DELIVERY_DATE_PROP } from "src/features/Cart/constants";
import { getNameValueProp } from "src/shared/utils";
import { IMAGE_QUALITY } from "src/constants";
import { sumSubTotal } from "../utils";

type Props = {
  product: ProductFieldsFragment;
  inProductCard?: boolean;
};

export const UpSellingCard: React.FC<Props> = ({
  product,
  inProductCard = false,
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const { addCurrentSubTotal } = useCurrentSubTotal();
  const [cartData] = useCheckoutData();
  const [isOpen, setIsOpen] = useState(false);
  const cartIsBusy = useCartBusy();
  const { currentUpsellingList, addCurrentUpsellingList } =
    useCurrentUpsellingList();
  const productExistInCart = useProductExistInCart(product.id);
  const {
    addToCart,
    variant,
    deliveryDate: currentDeliveryDate,
    setDeliveryDate,
    setVariant,
  } = useProductForm({ product });
  const query = useQuery<DatePickerClientConfigT>(
    [
      {
        productId: product.id,
        isSubscription: product.isSubscription,
      },
      "datepicker",
    ],
    () =>
      loadDatePickerConfig({
        productId: product.id,
        isSubscription: product.isSubscription,
        userDate: new Date().toISOString(),
      })
  );
  const { shopifyProduct }: { shopifyProduct: ShopifyProductJson } = product;
  const { hasDisabledNextDayDelivery } = product;
  const hasPrice = Boolean(shopifyProduct?.priceRange);

  const datePickerConfig = query.data ?? ({} as DatePickerClientConfigT);
  const _variant: ShopifyVariantNode =
    product?.shopifyProduct?.variants.edges[0].node;

  let selectedDeliveryDate = !datePickerConfig.minDate
    ? ""
    : format(new Date(datePickerConfig.minDate) as Date, "MM-dd-yyyy");

  const isValidProduct = cartIsBusy || !hasPrice || productExistInCart;

  const [isHovering, setIsHovering] = useState(false);
  const firstProductCart: ShopifyLineItem[] =
    cartData?.cart?.lines?.edges?.map((p) => p.node) ?? [];
  const [showRemoveButton, setShowRemoveButton] = useState(false);
  const [showSwitchButton, setShowSwitchButton] = useState(false);
  const deliveryDateProp = getNameValueProp(
    firstProductCart[0]?.attributes ?? [],
    DELIVERY_DATE_PROP
  );
  const defaultDatetoUpsellingP = deliveryDateProp ?? selectedDeliveryDate;
  selectedDeliveryDate = hasDisabledNextDayDelivery
    ? selectedDeliveryDate
    : defaultDatetoUpsellingP;
  const addUpsellingListHandle = () => {
    const listOfProducts = product?.listUpsellingToExclude;
    const productsExcludedInCart = currentUpsellingList?.filter((cup) => {
      const excludedProductIds = listOfProducts.map((ep) => ep.id);
      return excludedProductIds.includes(cup.id) ?? [];
    });
    let newUpsellingSwitchList = currentUpsellingList ?? [];
    if (productsExcludedInCart && (productsExcludedInCart?.length ?? 0) > 0) {
      const excludedProductIds = listOfProducts.map((ep) => ep.id);
      newUpsellingSwitchList =
        currentUpsellingList?.filter((cup) => {
          return !excludedProductIds.includes(cup.id) ?? [];
        }) ?? [];
    }
    const upsellingProductAdded =
      currentUpsellingList?.includes(product) ?? false;
    if (!upsellingProductAdded) {
      const newUpsellingList = newUpsellingSwitchList ?? [];
      newUpsellingList?.push(product);
      addCurrentUpsellingList(newUpsellingList);
      setShowRemoveButton(true);
    }
  };
  const removeFromUpsellingListHandle = () => {
    const newUpsellingList =
      currentUpsellingList?.filter((p) => p.id !== product.id) ?? [];
    addCurrentUpsellingList(newUpsellingList);
    setShowRemoveButton(false);
    setShowSwitchButton(false);
  };
  useEffect(() => {
    setShowSwitchButton(false);
    const listOfProducts = product.listUpsellingToExclude;
    const haExcludedProductsInCart =
      (currentUpsellingList?.filter((cup) => {
        const excludedProductIds = listOfProducts.map((ep) => ep.id);
        return excludedProductIds.includes(cup.id) ?? [];
      })?.length ?? 0) > 0;
    if (haExcludedProductsInCart) {
      setShowSwitchButton(haExcludedProductsInCart);
      setShowRemoveButton(false);
    }

    const upsellingPadded = currentUpsellingList?.includes(product) ?? false;
    if (upsellingPadded) {
      setShowRemoveButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUpsellingList, product]);

  useEffect(() => {
    addCurrentSubTotal(sumSubTotal(currentUpsellingList));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addCurrentUpsellingList]);
  useEffect(() => {
    if (
      variant &&
      currentDeliveryDate &&
      variant?.id === _variant?.id &&
      !productExistInCart
    ) {
      addToCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variant, selectedDeliveryDate]);

  if (isValidProduct) {
    return null;
  }
  const image = product.gallery[0];
  const src = `${image.url}?w=400&h=400&crop=focalpoint&fit=crop&fp-x=0.4&dpr=2`;
  const minPrice = formatShopifyPrice(
    shopifyProduct?.priceRange.minVariantPrice as ShopifyMoneyV2
  );
  const isFreeProduct = minPrice === "$0.00";
  const salesPrice = formatShopifyPrice(
    shopifyProduct.compareAtPriceRange?.minVariantPrice
  );
  const hasSalesPrice =
    Number(shopifyProduct.compareAtPriceRange?.minVariantPrice.amount) > 0;
  const handleAddToCart = () => {
    if (variant?.id !== _variant?.id) {
      setVariant(_variant);
    }
    setDeliveryDate(selectedDeliveryDate);
  };
  const handleModalclick = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    setIsOpen(true);
  };
  const buttonActions = (isInModalPop: boolean, isButtonToAdd = false) => {
    return (
      <div
        css={[
          isInModalPop ? tw`relative` : tw`relative lg:absolute top-0`,
          tw`bg-opacity-50 w-full flex justify-start mb-2 lg:justify-center`,
        ]}
      >
        {!showRemoveButton && !isButtonToAdd && (
          <div css={tw`relative mt-2 lg:mt-4 bg-white rounded`}>
            <Button
              css={tw`!border border-black text-black min-w-[115px] h-10 text-sm font-roboto`}
              variant="outlined"
              size="small"
              disabled={cartIsBusy}
              fullWidth
              onClick={() => addUpsellingListHandle()}
            >
              <span css={tw`whitespace-nowrap px-4`}>
                {showSwitchButton ? "SWITCH" : "ADD"}
              </span>
            </Button>
          </div>
        )}

        {showRemoveButton && !isButtonToAdd && (
          <div css={tw`relative mt-2 lg:mt-4 bg-white rounded`}>
            <Button
              css={tw`!border border-black text-black min-w-[115px] h-10 text-sm font-roboto`}
              variant="outlined"
              size="small"
              disabled={cartIsBusy}
              fullWidth
              onClick={() => removeFromUpsellingListHandle()}
            >
              <span css={tw`whitespace-nowrap`}>REMOVE</span>
            </Button>
          </div>
        )}

        {isButtonToAdd && (
          <div css={tw`relative mt-2 lg:mt-4 bg-white rounded`}>
            <Button
              css={[
                tw`!border border-black text-black min-w-[115px] h-10 text-sm font-roboto`,
              ]}
              variant="outlined"
              size="small"
              disabled={cartIsBusy}
              onClick={handleAddToCart}
            >
              <span css={tw`whitespace-nowrap`}>{copy.add}</span>
            </Button>
          </div>
        )}
      </div>
    );
  };
  if (product.disableDatePicker) return null;
  return (
    <>
      <div
        css={[
          tw`pb-4`,
          isDesktop && inProductCard && tw`flex flex-wrap`,
          inProductCard
            ? tw`w-full md:pr-3 lg:(px-1 pr-1.5 w-1/3)`
            : tw`w-full my-4 mx-0`,
        ]}
      >
        <div>
          <div
            css={[tw` w-full relative`]}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
          >
            <Image
              src={src}
              layout="intrinsic"
              width={400}
              height={350}
              quality={IMAGE_QUALITY}
              alt={image.alt ?? ""}
            />
            {showRemoveButton && (
              <div>
                <AiFillCheckCircle
                  css={tw`absolute text-3xl top-1 right-1 text-gray-700`}
                />
              </div>
            )}
            <div
              css={[
                tw`flex flex-wrap lg:flex-row justify-center items-center`,
                tw`h-auto`,
                isDesktop && inProductCard ? tw`lg:(px-2 space-x-2)` : tw`p-2`,
              ]}
            >
              <p
                css={[
                  tw`text-sm font-roboto text-center w-full font-medium underline underline-offset-2 h-7 hover:(font-bold)`,
                  isDesktop && inProductCard && tw`h-10`,
                ]}
              >
                <span
                  tabIndex={0}
                  onKeyPress={handleModalclick}
                  role="button"
                  css={[
                    tw`text-left block w-full text-base`,
                    !isDesktop && tw`truncate`,
                    inProductCard && tw`lg:(text-center inline w-auto text-sm)`,
                  ]}
                  onClick={handleModalclick}
                >
                  {product.title}
                </span>
              </p>
              {hasSalesPrice ? (
                <p
                  css={[
                    tw`font-roboto font-normal text-base text-left w-full`,
                    inProductCard &&
                      tw`lg:(space-x-2 text-center text-sm w-auto)`,
                  ]}
                >
                  <span css={tw`font-bold`}>{minPrice}</span>
                  <span css={[tw`line-through text-base text-gray-400`]}>
                    {salesPrice}
                  </span>
                </p>
              ) : (
                <p
                  css={[
                    tw`text-base font-roboto font-normal text-base text-left w-full`,
                    inProductCard && tw`lg:(text-sm text-center w-auto)`,
                  ]}
                >
                  {isFreeProduct ? "FREE" : minPrice}
                </p>
              )}
            </div>
            {isDesktop && isHovering && inProductCard && buttonActions(false)}
            {isDesktop &&
              isHovering &&
              !inProductCard &&
              buttonActions(false, true)}
          </div>

          {!inProductCard && !isDesktop && (
            <div css={tw`py-2 space-y-2`}>{buttonActions(true, true)}</div>
          )}
        </div>
        {!isDesktop && (
          <div css={tw`w-full`}>
            {!isDesktop && inProductCard && buttonActions(false)}
          </div>
        )}
      </div>
      <Dialog
        maxWidth={isDesktop ? "md" : "sm"}
        fullWidth
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        <DialogContent css={tw`font-roboto pb-4 px-4 lg:(px-2 pb-2)`}>
          <div css={tw`flex flex-wrap -mt-3`}>
            <div css={tw`w-full lg:w-1/2`}>
              <div
                key={image.id}
                css={[
                  tw`w-full relative`,
                  isDesktop ? [{ height: 550 }] : tw`h-52 sm:h-96`,
                ]}
              >
                <div
                  css={tw`w-full sticky top-0 absolute flex justify-end lg:hidden z-10`}
                >
                  <IconButton
                    onClick={() => setIsOpen(false)}
                    css={tw`text-gray-500`}
                    size="large"
                  >
                    <MdClose />
                  </IconButton>
                </div>
                <Image
                  src={`${product.gallery[0].url}?w=900h=1200`}
                  css={tw`object-cover`}
                  layout="fill"
                  quality={IMAGE_QUALITY}
                  alt={image?.alt ?? ""}
                />
              </div>
            </div>
            <div css={tw`w-full lg:w-1/2  pb-2`}>
              <div css={tw`flex justify-end hidden lg:flex`}>
                <div>
                  <IconButton
                    onClick={() => setIsOpen(false)}
                    css={tw`text-gray-500`}
                    size="large"
                  >
                    <MdClose />
                  </IconButton>
                </div>
              </div>
              <div css={tw`px-4 lg:px-12 mt-10`}>
                <div css={tw`flex flex-wrap justify-center pb-6`}>
                  <ContentSectionTitle
                    css={tw`font-playfair text-left capitalize text-2xl lg:text-4xl leading-tight w-full`}
                  >
                    {product.title}
                  </ContentSectionTitle>
                  <div css={tw`border w-full mt-6`} />
                </div>
                <h2 css={[tw`text-lg font-roboto text-left font-bold`]}>
                  {minPrice}
                </h2>
                <div
                  css={tw`text-sm lg:text-base`}
                  dangerouslySetInnerHTML={{
                    __html: product.description ?? "",
                  }}
                />
                <div css={tw`mt-10`}>{buttonActions(true, !inProductCard)}</div>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};
