/* eslint-disable react/function-component-definition */
import * as React from "react";
import tw from "twin.macro";
import { SubMenuSettingRecord } from "src/generated/datocms-types";
import Image from "next/legacy/image";
import { getCmsMenuLinksHref } from "src/shared/utils/index";
import { IMAGE_QUALITY } from "src/constants";
import { NavLinkItem } from "../components/PageHeader";

type Props = {
  data: SubMenuSettingRecord;
};

const PageMegaMenu: React.FC<Props> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {data.leftTitle?.length && (
        <div
          css={[
            tw`flex items-start justify-end w-auto font-roboto font-bold text-sm  cursor-pointer`,
          ]}
        >
          <NavLinkItem
            css={tw`uppercase py-0 lg:pt-6`}
            data={data.leftTitle[0]!}
          />
        </div>
      )}
      {data.middleSubMenuItem && (
        <div css={[tw`flex items-start w-auto font-roboto`]}>
          {data.middleSubMenuItem.map((ms) => {
            if (!ms.item?.length) {
              return null;
            }
            return (
              <div
                key={ms.id}
                css={tw`w-auto font-roboto pl-8 h-full pr-1`}
              >
                <ul css={tw`uppercase font-bold text-sm pt-6 pb-1`}>
                  {ms?.title || ""}
                </ul>
                <div css={tw`list-none text-base cursor-pointer pt-2 pb-4`}>
                  {ms.item.map((item) => {
                    if (!item) return null;
                    return (
                      <li key={item.id} css={[{ "font-size": "13px" }]}>
                        <NavLinkItem css={tw` py-0 lg:py-0.5`} data={item} />
                      </li>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
      {data.rightImage && (
        <div css={[tw` w-auto  p-10 border-l-2 ml-14`]}>
          {data.rightLink && (
            <div css={tw`flex flex-col  w-80`}>
              <a href={getCmsMenuLinksHref(data.rightLink[0]!)}>
                <div css={tw`relative h-44 w-full`}>
                  <Image
                    src={`${data.rightImage.url}?w=350`}
                    layout="fill"
                    quality={IMAGE_QUALITY}
                    objectFit="cover"
                    alt={data.rightImage.alt ?? ""}
                  />
                </div>
              </a>

              <div>
                <NavLinkItem
                  css={tw`uppercase font-roboto font-bold text-sm py-0 lg:py-1`}
                  data={data.rightLink[0]!}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default PageMegaMenu;
