import React, { FC } from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import NextLink from "next/link";
import { LogoText } from "src/shared/components/Icons";
import tw from "twin.macro";
import Image from "next/legacy/image";
import { IMAGE_QUALITY } from "src/constants";

type SimpleHeaders = {
  hasLogo?: boolean;
  urlLogo?: string;
};

// eslint-disable-next-line react/function-component-definition
export const SimpleHeader: FC<SimpleHeaders> = ({
  hasLogo = false,
  urlLogo = "",
}) => {
  const [openPopup, setOpenPopup] = React.useState(false);
  return (
    <header
      id="js-pageHeader"
      css={[
        tw`bg-white border-b border-solid border-gray-300 sticky top-0 z-20`,
        openPopup && tw`hidden`,
      ]}
    >
      <FixedContainer>
        <div css={tw`flex items-center justify-center py-0 lg:py-4`}>
          <NextLink href="/" passHref css={tw`block py-3 lg:py-0`}>

            {hasLogo ? (
              <div css={tw`h-bloomsy-logo-sm lg:h-bloomsy-logo-lg`}>
                <Image
                  css={tw`object-contain w-auto text-bloomsy-red h-bloomsy-logo-sm lg:h-bloomsy-logo-lg`}
                  src={`${urlLogo}?w=1500&h=200`}
                  layout="fill"
                  quality={IMAGE_QUALITY}
                  alt="Logo"
                />
              </div>
            ) : (
              <LogoText
                css={tw`w-auto text-bloomsy-red h-bloomsy-logo-sm lg:h-bloomsy-logo-lg`}
              />
            )}

          </NextLink>
        </div>
      </FixedContainer>
    </header>
  );
};
