import React from "react";
import tw from "twin.macro";
import {
  initialSuperGiftItem,
  SUPERGIFT_PROP,
  SuperGiftItemT,
  SuperGiftState,
} from "src/features/SuperGift/SuperGiftProductForm";
import { getSuperGiftItemTitle } from "src/features/SuperGift/SuperGiftItem";
import { ShopifyLineItem } from "src/shared/types/shopify";
import { formatDate, timezoneDate } from "src/shared/utils/dates";
import { parse } from "date-fns";

type Props = {
  lineItem: ShopifyLineItem;
};
// eslint-disable-next-line react/function-component-definition
export const SuperGiftCartSummary: React.FC<Props> = ({ lineItem }) => {
  let items: SuperGiftState = [];
  lineItem?.attributes?.forEach((prop) => {
    if (prop.key.indexOf(SUPERGIFT_PROP) === -1) return null;
    const match = prop.key.match(/#(?<itemNum>\d)#/);
    const { itemNum } = match?.groups ?? {};
    if (!itemNum) return null;
    const itemIndex = parseInt(itemNum, 10) - 1;
    const isDeliveryDateProp = prop.key.includes("Delivery_Date");
    const isGiftMessageProp = prop.key.includes("Gift_Message");
    const newItem: SuperGiftItemT = items[itemIndex]
      ? { ...items[itemIndex] }
      : { ...initialSuperGiftItem };
    if (isDeliveryDateProp) {
      newItem.deliveryDate = formatDate(
        timezoneDate(parse(prop.value, "MM-dd-yyyy", new Date()) || ""),
        "MMM dd, yyyy"
      );
    }
    if (isGiftMessageProp) {
      newItem.giftMessage = prop.value;
    }
    items = [
      ...items.slice(0, itemIndex),
      newItem,
      ...items.slice(itemIndex + 1),
    ];
  });
  return (
    <div css={tw`text-sm font-roboto mt-0 lg:mt-3`}>
      {items.map((item, idx) => {
        const title = getSuperGiftItemTitle(`${idx + 1}`);
        return (
          <>
            <p>{title} Delivery Date:</p>
            <p>{item.deliveryDate}</p>
          </>
        );
      })}
    </div>
  );
};
