import wretch from "wretch";

export const authApi = wretch("/api/auth")
  .content("application/json")
  .accept("application/json");

export const customerPortalApiClient = wretch("/api/customer")
  .content("application/json")
  .accept("application/json");

export const LOGIN_URL = "/login";
export const RESET_PASS_URL = "/customer/reset";
