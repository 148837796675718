/* eslint-disable react/function-component-definition */
import { FC, useEffect, useState } from "react";
import tw from "twin.macro";
import Cookies from "js-cookie";
import { IconButton } from "src/shared/components/IconButton";
import { MdClose as CloseIcon } from "react-icons/md";

const COOKIE_NAME = "__bloomsybox_annoucement_hidden";
const serializeMsg = (m: string) => window.btoa(m.toLowerCase());

// convert a Unicode string to a string in which each 16-bit unit occupies only one byte
const toBinary = (text: string) => {
  const codeUnits = new Uint16Array(text.length);
  for (let i = 0; i < codeUnits.length; i++) {
    codeUnits[i] = text.charCodeAt(i);
  }
  const charCodes = new Uint8Array(codeUnits.buffer);
  let result = "";
  for (let i = 0; i < charCodes.byteLength; i++) {
    result += String.fromCharCode(charCodes[i]);
  }
  return result;
};

export const THEME_RED = "RED";
export const THEME_LIGHT = "LIGHT";

type Props = {
  message: string;
  theme?: string;
  linkText: string;
  linkUrl: string;
};
export const TopBarNotice: FC<Props> = ({
  message = "",
  theme = THEME_RED,
  linkText = "",
  linkUrl = "",
}) => {
  const placeholder = "{{barLink}}";
  const msg = message.trim().replace(placeholder, linkText);
  const fullMsg = message?.replace(
    placeholder,
    `<a href="${linkUrl}">${linkText}</a>`
  );
  const [visible, setVisible] = useState(false);
  const hideMessage = () => {
    // we store the b64 message in the cookie
    Cookies.set(COOKIE_NAME, serializeMsg(toBinary(msg)));
    setVisible(false);
  };
  useEffect(() => {
    // check if the user has hidden this message
    const cookieVal = Cookies.get(COOKIE_NAME);
    if (!cookieVal) {
      setVisible(true);
    }
    // if the hidden cookie msg is not same as the current message,
    // we need to show the bar again
    if (cookieVal !== serializeMsg(toBinary(msg))) {
      setVisible(true);
      Cookies.remove(COOKIE_NAME);
    }
  }, [msg]);
  if (!visible || msg.length <= 0) return null;
  const isRedTheme = theme === THEME_RED;
  const isLightTheme = theme === THEME_LIGHT;
  return (
    <div
      css={[
        tw`font-roboto text-xs text-center block w-full  border-t border-b border-solid z-50`,
        isRedTheme && tw`text-white bg-bloomsy-darkred border-bloomsy-red`,
        isLightTheme &&
        tw`text-bloomsy-black bg-bloomsy-lightAnnouncement border-bloomsy-lightAnnouncement`,
        {color: "#515151"}
      ]}
    >
      <div
        css={[
          tw`flex flex-row justify-center items-center font-roboto h-[46px] lg:h-[34px] text-sm leading-6`,
        ]}
      >
        <p
          dangerouslySetInnerHTML={{ __html: fullMsg }}
          css={[{ "&>a": tw`underline underline-offset-4` }]}
        />
      </div>
    </div>
  );
};
