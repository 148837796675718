/* eslint-disable react/function-component-definition */
import { FC, useEffect } from "react";
import tw, { theme as twTheme } from "twin.macro";
import Image from "next/legacy/image";
import { ProductFieldsCartFragment } from "src/generated/datocms-types";
import { CartItemQuantity } from "src/features/Cart/CartPage/CartItemQuantity";
import { IconButton } from "src/shared/components/IconButton";
import {
  cartOperations,
  useProductForm,
  useInvalidDeliveryDates,
} from "src/features/Cart/state";
import { IoTrashOutline } from "react-icons/io5";
import { formatPrice } from "src/shared/helpers";
import { Button, Slide, useMediaQuery } from "@mui/material";
import {
  getProductRecurringLabel,
  isSuperGiftProduct,
  isWeeklySuperGiftProduct,
} from "src/shared/utils/products";
import { getVariantFrequency } from "src/features/Cart/utils";
import { SuperGiftCartSummary } from "src/features/SuperGift/SuperGiftCartSummary";
import {
  DELIVERY_DATE_PROP,
  ONE_TIME_VARIANT_PROP,
  ONE_TIME_VARIANT_ID_PROP,
  VIRTUAL_ADD_ON,
  IS_GIFT_PROP,
  AUTO_RENEW_PROP,
} from "src/features/Cart/constants";
import { getNameValueProp } from "src/shared/utils";
import { formatDate, timezoneDate } from "src/shared/utils/dates";
import { parse } from "date-fns";
import { IMAGE_QUALITY } from "src/constants";
import { ShopifyLineItem, ShopifyVariantNode } from "src/shared/types/shopify";
import { ProductGiftMessageForm } from "src/features/ProductForm/ProductGiftMessageForm";
import { DeliveryDate } from "./DeliveryDate";

const Arrow = ({
  left,
  visible = true,
}: {
  left: boolean;
  visible?: boolean;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={[
        tw`w-[10px] h-[10px] fill-[#4D5562]`,
        !visible ? tw`invisible` : tw``,
      ]}
    >
      {left && (
        <path
          d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
      {!left && (
        <path
          d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
    </svg>
  );
};

type Props = {
  lineItem: ShopifyLineItem;
  product: ProductFieldsCartFragment;
  variant?: "full" | "drawer";
  handleOpenOrCloseSlideCalendar?: (productId: string) => void;
  openSlideCalendar?: string;
};

export const CartItemCardMobile: FC<Props> = ({
  lineItem,
  product,
  variant = "full",
  handleOpenOrCloseSlideCalendar,
  openSlideCalendar,
}) => {
  const isSuperGift = isSuperGiftProduct(product.id);
  const isInDrawer = variant === "drawer";
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const {
    isGift,
    setIsGift,
    giftEmail,
    setGiftEmail,
    giftMessage,
    setGiftMessage,
    setIsGiftMessageFormDirty,
    isAutoRenew,
    setIsAutoRenew,
    recipientName,
    setRecipientName,
  } = useProductForm({
    product,
  });
  const recurringFrequency = getProductRecurringLabel(
    product.title,
    product.tags
  );
  const { removeInvalidDeliveryDate } = useInvalidDeliveryDates();
  const variantFrequency = getVariantFrequency(
    lineItem.merchandise?.title ?? ""
  );
  const productTitle = product.title;
  const oneTimeVariantIdPropValue = getNameValueProp(
    lineItem.attributes ?? [],
    ONE_TIME_VARIANT_ID_PROP
  );
  const variantGallery = product.variants.find(
    (v) => v.id === oneTimeVariantIdPropValue
  );
  const hasVariantImage = !!variantGallery;
  const image = hasVariantImage
    ? variantGallery?.gallery[0]
    : product.gallery[0];
  const productImageSize = { w: isInDrawer || !isDesktop ? 110 : 150, h: 110 };
  const productImageSrc = `${
    image.url
  }?w=${450}&h=${400}&fit=crop&fp-x=0.4&dpr=2`;
  const isSub = !!lineItem.sellingPlanAllocation;
  const variantTitle = isSub ? lineItem.merchandise?.title : null;
  const isPrepay = variantFrequency
    ? (variantFrequency.chargeInterval ?? 1) /
        (variantFrequency.orderInterval ?? 1) >
      1
    : false;

  const productTypeLabelRecurring =
    isGift && !isAutoRenew && isPrepay ? "(non-recurring)" : "";
  const productTypeLabelFrequency = isPrepay ? "Prepaid" : recurringFrequency;
  const productTypeLabel = isSub
    ? `${productTypeLabelFrequency} Subscription ${productTypeLabelRecurring} `
    : null;
  const productPrice = (
    <span css={tw`font-roboto`}>
      {lineItem.cost?.amountPerQuantity.amount
        ? formatPrice(+lineItem.cost.amountPerQuantity.amount)
        : "0"}
    </span>
  );
  const isFreeProduct = productPrice.props.children === "$0.00";
  const productPriceString = isFreeProduct ? "FREE" : productPrice;
  const isUpsellingProduct = product.isUpselling;
  const variantProduct: ShopifyVariantNode =
    product.shopifyProduct?.variants.edges[0].node;
  const isVirtualProduct = variantProduct.sku === VIRTUAL_ADD_ON;
  const productQty =
    !isSub && !isVirtualProduct && !isFreeProduct ? (
      <CartItemQuantity lineItem={lineItem} />
    ) : null;
  const deliveryDateProp = getNameValueProp(
    lineItem.attributes ?? [],
    DELIVERY_DATE_PROP
  );
  const deliveryDate = deliveryDateProp
    ? formatDate(
        timezoneDate(parse(deliveryDateProp, "MM-dd-yyyy", new Date()) || ""),
        "MMM dd, yyyy"
      )
    : null;
  const oneTimeVariantPropValue = getNameValueProp(
    lineItem.attributes ?? [],
    ONE_TIME_VARIANT_PROP
  );

  useEffect(() => {
    if (lineItem) {
      const isGiftProp = getNameValueProp(
        lineItem?.attributes ?? [],
        IS_GIFT_PROP
      );
      const autoRenewProp = getNameValueProp(
        lineItem?.attributes ?? [],
        AUTO_RENEW_PROP
      );

      if (isGiftProp) {
        setIsGift(true);
        if (autoRenewProp) setIsAutoRenew(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemove = () => {
    cartOperations.removeItem(lineItem);
    removeInvalidDeliveryDate(lineItem);
  };

  const closeButton = (
    <IconButton onClick={() => handleRemove()} css={tw`w-[20px] h-[20px]`}>
      <IoTrashOutline css={tw`text-black w-[20px] h-[20px]`} />
    </IconButton>
  );

  const closeSlideCalendar = () => {
    if (handleOpenOrCloseSlideCalendar) handleOpenOrCloseSlideCalendar("");
  };

  const handleEdit = () => {
    if (handleOpenOrCloseSlideCalendar) {
      handleOpenOrCloseSlideCalendar(lineItem.merchandise?.id ?? "");
    }
  };

  return (
    <div>
      <div
        css={[
          tw`rounded bg-white pt-7 pb-6 relative`,
          isInDrawer ? tw`border-b` : tw`mb-6`,
        ]}
      >
        <div css={tw`w-full flex items-start px-5`}>
          {image && (
            <div
              css={[
                tw`relative flex-shrink-0`,
                { width: productImageSize.w, height: productImageSize.h },
              ]}
            >
              <Image
                css={tw`object-cover`}
                src={productImageSrc}
                layout="fill"
                quality={IMAGE_QUALITY}
                alt={image.alt ?? ""}
              />
            </div>
          )}
          <div css={[{ width: isInDrawer ? "auto" : 300 }, tw`w-full ml-4`]}>
            <div css={tw`tracking-normal font-roboto`}>
              <div
                css={tw`text-black text-base leading-7 flex justify-between gap-2`}
              >
                <span>{productTitle}</span>
                <span>{closeButton}</span>
              </div>

              {oneTimeVariantPropValue && (
                <div css={tw`text-sm text-left text-bloomsy-midgray`}>
                  <span css={tw`block`}>{oneTimeVariantPropValue}</span>
                </div>
              )}

              <div css={tw`text-left`}>
                {!isSuperGift && !isSub && (
                  <span css={tw`block text-sm text-bloomsy-midgray`}>
                    {variantTitle}
                  </span>
                )}
              </div>

              <div css={[tw`text-bloomsy-midgray text-sm leading-5`]}>
                {!isSuperGift && (
                  <>
                    <p>
                      {isUpsellingProduct && <span>Estimated </span>}Delivery
                      Date:
                    </p>
                    <p>{deliveryDate}</p>
                  </>
                )}

                {isSuperGift && (
                  <div>
                    <SuperGiftCartSummary lineItem={lineItem} />
                  </div>
                )}
              </div>

              {!isUpsellingProduct && !isSuperGift && (
                <div css={tw`text-bloomsy-midgray text-sm leading-5`}>
                  <span>Gift Message: </span>
                  <span>{isGift ? "YES" : "NO"}</span>
                </div>
              )}

              {!isUpsellingProduct && !isSuperGift && (
                <div css={tw`mt-2 text-bloomsy-midgray leading-5 text-sm`}>
                  <a
                    href={undefined}
                    onClick={handleEdit}
                    css={[
                      tw`underline underline-offset-2`,
                      isDesktop && tw`cursor-pointer`,
                    ]}
                  >
                    Edit
                  </a>
                </div>
              )}
            </div>

            {(isInDrawer || !isDesktop) && (
              <div>
                <div
                  css={[
                    tw`w-full flex items-center justify-between mt-3`,
                    (isSuperGift || isFreeProduct || isVirtualProduct) &&
                      tw`justify-end`,
                  ]}
                >
                  {productQty && <div>{productQty}</div>}
                  {!productQty &&
                    productTypeLabel &&
                    !isWeeklySuperGiftProduct(product.id) &&
                    !isSuperGift && (
                      <div css={tw`text-sm font-roboto text-bloomsy-midgray`}>
                        {!isWeeklySuperGiftProduct(product.id) &&
                        ((isGift && isAutoRenew) ||
                          (isPrepay && !isGift && !isSuperGift) ||
                          (isSub && !isPrepay)) ? (
                          <div css={tw`tracking-normal text-sm`}>
                            <p>
                              <span>Payment: </span>
                              <span>
                                {`Every ${variantFrequency?.chargeInterval} ${variantFrequency?.unitType}`}
                                {(variantFrequency?.chargeInterval ?? 0) > 1
                                  ? "s"
                                  : null}
                              </span>
                            </p>
                            <p>
                              <span>Delivery Schedule: </span>
                              <span>{recurringFrequency}</span>
                            </p>
                          </div>
                        ) : null}
                      </div>
                    )}
                  <span
                    css={tw`block font-bold text-base leading-7 text-black tracking-normal`}
                  >
                    {productPriceString}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!isUpsellingProduct && !isSuperGift && (
        <Slide
          in={openSlideCalendar === lineItem.merchandise?.id}
          direction="left"
          css={tw`max-h-full`}
        >
          <div
            css={[
              tw`absolute top-0 z-20 w-full h-full bg-white block font-roboto`,
            ]}
          >
            <nav
              css={tw`uppercase font-bold font-roboto text-sm border-b border-[#D2D5DB] mb-6 tracking-wider leading-[48px]`}
            >
              <a
                href={undefined}
                onClick={closeSlideCalendar}
                css={[
                  tw`flex items-center justify-between cursor-default`,
                  isDesktop && tw`cursor-pointer`,
                ]}
              >
                <span css={tw`ml-6`}>
                  <Arrow left />
                </span>
                <span
                  css={[
                    tw`block h-full w-full flex justify-center items-center`,
                  ]}
                >
                  Back
                </span>
                <span css={tw`mr-6`}>
                  <Arrow left={false} visible={false} />
                </span>
              </a>
            </nav>

            <div css={tw`flex justify-center px-2`}>
              <DeliveryDate
                lineItem={lineItem}
                product={product}
                variant="drawer"
                openSlideCalendar={openSlideCalendar}
                handleOpenOrCloseSlideCalendar={handleOpenOrCloseSlideCalendar}
              />
            </div>
            {!isSuperGift && !isUpsellingProduct && (
              <div css={tw`py-2 px-5`}>
                <ProductGiftMessageForm
                  product={product}
                  lineItem={lineItem}
                  isGift={isGift}
                  setIsGift={setIsGift}
                  giftEmail={giftEmail}
                  setGiftEmail={setGiftEmail}
                  giftMessage={giftMessage}
                  setGiftMessage={setGiftMessage}
                  setIsGiftMessageFormDirty={setIsGiftMessageFormDirty}
                  isAutoRenew={isAutoRenew}
                  setIsAutoRenew={setIsAutoRenew}
                  isPrepay={isPrepay}
                  isSuperGift={isSuperGift}
                  recipientName={recipientName}
                  setRecipientName={setRecipientName}
                />
                {isGift && (
                  <Button
                    css={tw`!border border-black text-black min-w-[100px] h-10 text-sm font-roboto hover:(!border-bloomsy-gray50)`}
                    variant="outlined"
                    size="small"
                    onClick={() => closeSlideCalendar()}
                  >
                    <span css={tw`whitespace-nowrap font-bold`}>Done</span>
                  </Button>
                )}
              </div>
            )}
          </div>
        </Slide>
      )}
    </div>
  );
};
