/* eslint-disable react/function-component-definition */
import { FC, ReactNode, useState } from "react";
import * as React from "react";
import FixedContainer from "src/shared/components/FixedContainer";
import tw, { theme as twTheme } from "twin.macro";
import {
  FaYoutube,
  FaPinterest,
  FaTwitter,
  FaInstagram,
  FaFacebookF,
} from "react-icons/fa";
import { useSession } from "next-auth/react";
import { IconType } from "react-icons";
import { GetLayoutDataQuery } from "src/generated/datocms-types";
import { getCmsMenuLinksHref } from "src/shared/utils";
import {
  Accordion as AccordionMaterialUI,
  AccordionDetails,
  AccordionSummary as AccordionSummaryMaterialUI,
  useMediaQuery,
} from "@mui/material";
import { withStyles } from "tss-react/mui";
import { PlusIcon, MinusIcon } from "src/shared/components/Icons";

type NavLinkItem = {
  href: string;
  label?: string;
  children?: ReactNode;
};
type SocialIconsProps = {
  data: NavLinkItem[];
};
type PageFooterProps = {
  data: GetLayoutDataQuery["footer"];
};

function getSocialIcon(href: string): IconType | null {
  if (href.indexOf("facebook.com") > -1) {
    return FaFacebookF;
  }
  if (href.indexOf("instagram.com") > -1) {
    return FaInstagram;
  }
  if (href.indexOf("twitter.com") > -1) {
    return FaTwitter;
  }
  if (href.indexOf("youtube.com") > -1) {
    return FaYoutube;
  }
  if (href.indexOf("pinterest.com") > -1) {
    return FaPinterest;
  }
  return null;
}

const Accordion = withStyles(AccordionMaterialUI, {
  root: {
    boxShadow: "none",
    "&::before": {
      backgroundColor: "#D2D5DB",
      transition: "none",
    },
    "&.Mui-expanded": {
      margin: "0px",
    },
  },
});
const AccordionSummary = withStyles(AccordionSummaryMaterialUI, {
  root: {
    borderBottom: "1px solid #D2D5DB",
    "&.Mui-expanded": {
      minHeight: "48px",
      borderTop: "1px solid #D2D5DB",
    },
  },

  content: {
    "&.Mui-expanded": {
      margin: "0px",
    },
  },
  expandIconWrapper: {
    "& svg": {
      color: "#4D5562",
    },
  },
});
const FooterLinkAccordion = ({ data }: PageFooterProps) => {
  const [activeAccordionId, setActiveAccordionId] = useState<string>("");

  const handleChangeAccordion = (
    e: React.SyntheticEvent<EventTarget>,
    isExpanded: boolean,
    idMenu: string
  ) => {
    if (isExpanded) setActiveAccordionId(idMenu);
    else setActiveAccordionId("");
  };
  const isActiveAccordion = (idMenu: string) => {
    return activeAccordionId === idMenu;
  };

  if (!data) return null;

  return (
    <>
      <Accordion
        css={tw`w-full`}
        onChange={(e, isExpanded) => {
          handleChangeAccordion(e, isExpanded, "leftMenu");
        }}
        expanded={isActiveAccordion("leftMenu")}
      >
        <AccordionSummary
          expandIcon={
            isActiveAccordion("leftMenu") ? (
              <MinusIcon css={tw`w-6 h-6 p-0 text-black`} />
            ) : (
              <PlusIcon css={tw`w-6 h-6 p-0  text-black`} />
            )
          }
          css={tw`font-bold text-sm font-roboto uppercase tracking-wider leading-5`}
        >
          {data.leftMenuTitle}
        </AccordionSummary>
        {data.leftMenuLinks?.map((link, index) => {
          if (!link) return null;

          const menuHref = getCmsMenuLinksHref(link);
          const externalLinkProps = link.isExternal
            ? { rel: "noopener noreferrer", target: "_blank" }
            : {};
          return (
            <AccordionDetails
              key={link.id}
              css={[index === 0 ? tw`mt-5` : tw``]}
            >
              <a
                {...externalLinkProps}
                css={tw`text-black leading-7 text-base font-roboto`}
                href={menuHref}
              >
                {link.title}
              </a>
            </AccordionDetails>
          );
        })}
      </Accordion>
      <Accordion
        css={tw`w-full`}
        onChange={(e, isExpanded) => {
          handleChangeAccordion(e, isExpanded, "middleMenu");
        }}
        expanded={isActiveAccordion("middleMenu")}
      >
        <AccordionSummary
          expandIcon={
            isActiveAccordion("middleMenu") ? (
              <MinusIcon css={tw`w-6 h-6 p-0 text-black`} />
            ) : (
              <PlusIcon css={tw`w-6 h-6 p-0  text-black`} />
            )
          }
          css={tw`font-bold text-sm font-roboto uppercase tracking-wider leading-5`}
        >
          {data.middleMenuTitle}
        </AccordionSummary>
        {data.middleMenuLinks?.map((link, index) => {
          if (!link) return null;

          const menuHref = getCmsMenuLinksHref(link);
          const externalLinkProps = link.isExternal
            ? { rel: "noopener noreferrer", target: "_blank" }
            : {};
          return (
            <AccordionDetails
              key={link.id}
              css={[index === 0 ? tw`mt-5` : tw``]}
            >
              <a
                {...externalLinkProps}
                css={tw`text-black leading-7 text-base font-roboto`}
                href={menuHref}
              >
                {link.title}
              </a>
            </AccordionDetails>
          );
        })}
      </Accordion>
      <Accordion
        css={tw`w-full`}
        onChange={(e, isExpanded) => {
          handleChangeAccordion(e, isExpanded, "rightMenu");
        }}
        expanded={isActiveAccordion("rightMenu")}
      >
        <AccordionSummary
          expandIcon={
            isActiveAccordion("rightMenu") ? (
              <MinusIcon css={tw`w-6 h-6 p-0 text-black`} />
            ) : (
              <PlusIcon css={tw`w-6 h-6 p-0  text-black`} />
            )
          }
          css={tw`font-bold text-sm font-roboto uppercase tracking-wider leading-5`}
        >
          {data.rightMenuTitle}
        </AccordionSummary>
        {data.rightMenuLinks?.map((link, index) => {
          if (!link) return null;

          const menuHref = getCmsMenuLinksHref(link);
          const externalLinkProps = link.isExternal
            ? { rel: "noopener noreferrer", target: "_blank" }
            : {};
          return (
            <AccordionDetails
              key={link.id}
              css={[index === 0 ? tw`mt-5` : tw``]}
            >
              <a
                {...externalLinkProps}
                css={tw`text-black leading-7 text-base font-roboto`}
                href={menuHref}
              >
                {link.title}
              </a>
            </AccordionDetails>
          );
        })}
      </Accordion>
      <Accordion
        css={tw`w-full`}
        onChange={(e, isExpanded) => {
          handleChangeAccordion(e, isExpanded, "extraMenu");
        }}
        expanded={isActiveAccordion("extraMenu")}
      >
        <AccordionSummary
          expandIcon={
            isActiveAccordion("extraMenu") ? (
              <MinusIcon css={tw`w-6 h-6 p-0 text-black`} />
            ) : (
              <PlusIcon css={tw`w-6 h-6 p-0  text-black`} />
            )
          }
          css={tw`font-bold text-sm font-roboto uppercase tracking-wider leading-5`}
        >
          {data.extraMenuTitle}
        </AccordionSummary>
        <div css={tw`grid grid-cols-2`}>
        {data.extraItemsLinks?.map((link) => {
          if (!link) return null;

          const menuHref = getCmsMenuLinksHref(link);
          const externalLinkProps = link.isExternal
            ? { rel: "noopener noreferrer", target: "_blank" }
            : {};
          return (
            <AccordionDetails
              key={link.id}
            >
              <a
                {...externalLinkProps}
                css={tw`text-black leading-7 text-base font-roboto`}
                href={menuHref}
              >
                {link.title}
              </a>
            </AccordionDetails>
          );
        })}
        </div>
      </Accordion>
    </>
  );
};
const SocialIcons: FC<SocialIconsProps> = ({ data }) => {
  return (
    <ul
      css={tw`clear-both w-full flex text-lg items-center mx-auto mb-5 lg:justify-end lg:(space-x-4 mb-0)`}
    >
      {data.map((i) => {
        const SocialIcon = getSocialIcon(i.href);
        if (!SocialIcon) return null;
        return (
          <li
            css={tw`block text-black hover:text-bloomsy-red mr-5 lg:(mr-0)`}
            key={i.href}
          >
            <a
              css={tw`block`}
              target="_blank"
              rel="noopener noreferrer"
              href={i.href}
              title={i.label}
            >
              <SocialIcon css={tw`w-6 h-6 lg:(w-4 h-4)`} />
            </a>
          </li>
        );
      })}
    </ul>
  );
};
const PageFooter: FC<PageFooterProps> = ({ data }) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const xlUp = useMediaQuery(`(min-width: ${twTheme`screens.xl`})`);
  const isLargeDesktop = useMediaQuery(`(min-width: 1366px)`);
  const [hasStyleApplied, setHasStyleApplied] = useState(false);
  const isMobile = !isDesktop && !xlUp;
  const containerWidth = !isLargeDesktop ? "1200px" : "1360px";
  const { data: session } = useSession();
  React.useEffect(() => {
    if (session)
    {
      const upzelohash = sessionStorage.getItem("upzeloSession");
      const script = document.createElement('script');
      script.src = 'https://assets.upzelo.com/launcher/index.js';
      script.setAttribute('id', 'upzpdl');
      script.setAttribute('type', 'module');
      script.setAttribute('appId', 'upz_app_da2146f4f786');
      script.setAttribute('customerId', session?.shopifyUser.id);
      script.setAttribute('hash', upzelohash ?? "");
      script.setAttribute('crossorigin', 'anonymous');
      script.setAttribute('defer', '');

      document.body.appendChild(script);
        return () => {
          document.body.removeChild(script);
        };
      }
  }, [session]);
  React.useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("upzelo-app");
      if (element) {
        element.setAttribute(
          "style",
          "display: block !important;"
        );
        setHasStyleApplied(true);
      }
    }, 2500);
  }, [setHasStyleApplied]); 
  React.useEffect(() => {
    setTimeout(() => {
      const element = document.getElementById("email_110324532");
      const container = document.getElementById("KlaviyoForm");
      if (container) {
        const div = container.getElementsByTagName("div");
        if (div) {
          for (let i = 0; i < div.length; i++) {
            let el = div[i];
            el.style.background = "#FFFFFF";
            el.style.padding = "0px";
          }
        }
        const button = container.getElementsByTagName("button");
        if (button) {
          for (let i = 0; i < button.length; i++) {
            let el = button[i];
            el.style.background = "#FFFFFF";
            el.style.color = "#000000";
            el.style.fontWeight = "Bold";
            el.style.fontFamily = "Roboto";
            el.style.fontSize = "14px";
            el.style.textDecoration = "underline";
            el.style.textUnderlineOffset = "4px";
            el.style.padding = "20px 20px 20px 4px";
          }
        }
      }
      if (element) {
        element.setAttribute(
          "style",
          "border:none !important; background:#FFFFFF; padding:20px 4px 20px 20px;"
        );
        setHasStyleApplied(true);
      }
    }, 2500);
  }, [setHasStyleApplied]);

  if (!data) return null;
  const socialLinks: NavLinkItem[] = [
    { href: data.facebook ?? "", label: "Facebook" },
    { href: data.instagram ?? "", label: "Instagram" },
    { href: data.twitter ?? "", label: "Twitter" },
    {
      href: data.youtube ?? "",
      label: "YouTube",
    },
    { href: data.pinterest ?? "", label: "Pinterest" },
  ];
  return (
    <section css={tw`py-10 lg:py-20`}>
      <footer>
        {isDesktop && (
          <div
          css={[tw`w-full mb-5 px-5 py-10`,{ background: "#FAF8F6" }]}
          >
            <FixedContainer css={tw`flex justify-center items-center`}>
              <div css={tw`w-1/4 text-right`}>
              <h6
                  css={tw`uppercase tracking-wider leading-5 font-roboto font-bold text-sm text-black lg:(font-roboto leading-normal)`}
                >
                 LET’S KEEP IN TOUCH
                </h6>
              </div>
              <div css={tw`w-4/6 flex justify-center`}>
              <div
                  id="KlaviyoForm"
                  css={[
                    tw`w-4/5`,
                    !hasStyleApplied && { minHeight: 60 },
                    { "&>": tw`border-none` },
                    [hasStyleApplied && { background: "#FFFFFF" }],
                  ]}
                  className="klaviyo-form-UkvmCR"
                />
              </div>
               
                <div css={[tw``]}>
                  <SocialIcons data={socialLinks} />
              </div>
              </FixedContainer>
        </div>
        )}
        {!isDesktop && (
          <div
          css={[tw`w-full mb-5 px-5 py-10`,{ background: "#FAF8F6" }]}
          >
            <div css={tw` justify-start items-center`}>
              <div css={tw`w-full text-left py-2`}>
              <h6
                  css={tw`uppercase tracking-wider leading-5 font-roboto font-bold text-sm text-black lg:(font-roboto leading-normal)`}
                >
                 LET’S KEEP IN TOUCH
                </h6>
              </div>
              <div css={tw`w-full flex justify-center py-2`}>
              <div
                  id="KlaviyoForm"
                  css={[
                    tw`w-full`,
                    !hasStyleApplied && { minHeight: 60 },
                    { "&>": tw`border-none` },
                    [hasStyleApplied && { background: "#FFFFFF" }],
                  ]}
                  className="klaviyo-form-UkvmCR"
                />
              </div>
               
                <div css={[tw`py-2`]}>
                  <SocialIcons data={socialLinks} />
              </div>
              </div>
        </div>
      )}
        <FixedContainer
          CustomContainer
          css={[
            isMobile && tw`container sm:px-0`,
            [
              xlUp && tw`mx-auto px-0`,
              { maxWidth: !xlUp ? "none !important" : containerWidth },
            ],
          ]}
        >
          <div css={tw`flex flex-row text-sm flex-wrap lg:justify-between`}>
            {isDesktop && (
              <div css={tw`flex flex-row w-full`}>
                <div css={tw`w-6/12 lg:w-1/6`}>
                  <h6
                    css={tw`uppercase tracking-wider font-roboto font-bold text-bloomsy-red font-roboto mb-4`}
                  >
                    {data.leftMenuTitle}
                  </h6>
                  <ul css={[tw`grid grid-cols-1`]}>
                    {data.leftMenuLinks?.map((i) => {
                      if (!i) return null;
                      // todo: refactor...DRY
                      const menuHref = getCmsMenuLinksHref(i);
                      const externalLinkProps = i.isExternal
                        ? { rel: "noopener noreferrer", target: "_blank" }
                        : {};
                      return (
                        <li css={tw`my-0.5`} key={i.id}>
                          <a
                            {...externalLinkProps}
                            css={tw`text-gray-700`}
                            href={menuHref}
                          >
                            {i.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div css={tw`w-6/12 lg:w-1/6`}>
                  <h6
                    css={tw`uppercase tracking-wider font-roboto font-bold text-bloomsy-red mb-4`}
                  >
                    {data.middleMenuTitle}
                  </h6>
                  <ul css={[tw`grid grid-cols-1`]}>
                    {data.middleMenuLinks?.map((i) => {
                      if (!i) return null;
                      // todo: refactor...DRY
                      const menuHref = getCmsMenuLinksHref(i);
                      const externalLinkProps = i.isExternal
                        ? { rel: "noopener noreferrer", target: "_blank" }
                        : {};
                      return (
                        <li css={tw`my-0.5`} key={i.id}>
                          <a
                            {...externalLinkProps}
                            css={tw`text-gray-700`}
                            href={menuHref}
                          >
                            {i.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div css={tw`w-6/12 lg:w-1/6`}>
                <h6
                    css={tw`uppercase tracking-wider font-roboto font-bold text-bloomsy-red mb-4`}
                  >
                    {data.rightMenuTitle}
                  </h6>
                  <ul css={[tw`grid grid-cols-1`]}>
                    {data.rightMenuLinks?.map((i) => {
                      if (!i) return null;
                      const menuHref = getCmsMenuLinksHref(i);
                      const externalLinkProps = i.isExternal
                        ? { rel: "noopener noreferrer", target: "_blank" }
                        : {};
                      return (
                        <li css={tw`my-0.5`} key={i.id}>
                          <a
                            {...externalLinkProps}
                            css={tw`text-gray-700`}
                            href={menuHref}
                          >
                            {i.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <div css={tw`w-6/12 lg:w-1/2`}>
                  <h6
                    css={tw`uppercase tracking-wider font-roboto font-bold text-bloomsy-red font-roboto mb-4`}
                  >
                    {data.extraMenuTitle}
                  </h6>
                  <ul css={[tw`grid grid-cols-3`]}>
                    {data.extraItemsLinks?.map((i) => {
                      if (!i) return null;
                      const menuHref = getCmsMenuLinksHref(i);
                      const externalLinkProps = i.isExternal
                        ? { rel: "noopener noreferrer", target: "_blank" }
                        : {};
                      return (
                        <li css={tw`my-0.5`} key={i.id}>
                          <a
                            {...externalLinkProps}
                            css={tw`text-gray-700`}
                            href={menuHref}
                          >
                            {i.title}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            )}
           
            {!isDesktop && <FooterLinkAccordion data={data} />}
          </div>
        </FixedContainer>
      </footer>
    </section>
  );
};
export default PageFooter;
