import {
  MenuLinksFragment,
  ProductFieldsShortFragment,
  PromotionalCardFieldsFragment,
} from "src/generated/datocms-types";
import { slugsPrefixMap } from "src/services/datocms/constants";
import {
  renderMetaTags as datoCmsRenderMetaTags,
  SeoMetaTagType,
} from "react-datocms";
import { ConfiguredMiddleware } from "wretch";
import { KeyValueProp, NameValueProp } from "src/shared/types";
import { log } from "src/shared/utils/log";
import { TokenBucket } from "limiter";

export const getCmsMenuLinksHref = (i: MenuLinksFragment) => {
  let menuHref = i.url ?? "";
  if (i.internalLink?._modelApiKey && i.internalLink?.slug) {
    menuHref = `${
      slugsPrefixMap[i.internalLink._modelApiKey as keyof typeof slugsPrefixMap]
    }${i.internalLink.slug}`;
  }
  return menuHref;
};
export const renderMetaTags = (data: SeoMetaTagType[]) => {
  const tags = data
    .map((t) => {
      const { tag, attributes } = t;
      const attrProp = attributes?.property ?? attributes?.name ?? "";
      const attrContent = attributes?.content ?? "";
      if (tag === "meta") {
        if (attrProp === "og:type" && attrContent === "article") return null;
        if (attrProp.indexOf("article:") > -1) return null;
      }
      return t;
    })
    .filter((t) => Boolean(t));
  return datoCmsRenderMetaTags(tags as SeoMetaTagType[]);
};
export const jmespathFilter = (fields: string[]) => {
  return fields
    .map((f) => {
      if (f.indexOf(":") > -1) {
        return f;
      }
      return `${f}:${f}`;
    })
    .join(",");
};

type LogMiddlewareConfig = {
  prefix: string;
  rateLimitHeader?: string;
};
type RequestsLogMiddleware = (
  config?: LogMiddlewareConfig
) => ConfiguredMiddleware;
export const requestsLogMiddleware: RequestsLogMiddleware =
  (
    config = {
      prefix: "fetch",
    }
  ) =>
  (next) =>
  (url, opts) => {
    log(`${config.prefix}:req:${opts.method}@${url}`);
    return next(url, opts)
      .then((res) => {
        log(`${config.prefix}:res:status:${res.status}:${opts.method}@${url}`);
        if (config.rateLimitHeader) {
          const val = res.headers.get(config.rateLimitHeader) || "n/a";
          log(`${config.prefix}:res:ratelimit:${val}`);
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
type RateLimitMiddleware = (
  limiter: TokenBucket,
  rateLimitHeaderFn?: (
    responseHeaders: Headers
  ) => Promise<{ tokensCount: number; bucketSize: number } | undefined>
) => ConfiguredMiddleware;
export const rateLimitMiddleware: RateLimitMiddleware =
  (limiter, rateLimitHeaderFn) => (next) => async (url, opts) => {
    const tokensToRemove = 1;
    // this throws if it exceeds limiter.bucketSize
    await limiter.removeTokens(tokensToRemove);
    const res = await next(url, opts);
    // update limiter count / bucket size if necessary
    if (rateLimitHeaderFn) {
      const result = await rateLimitHeaderFn(res.headers);
      if (result) {
        // eslint-disable-next-line no-param-reassign
        limiter.content = result.tokensCount;
        // eslint-disable-next-line no-param-reassign
        limiter.bucketSize = result.bucketSize;
      }
    }
    return res;
  };

export const getNameValueProp = (
  collection: KeyValueProp[],
  key: string
): string | undefined => {
  return collection.find((note) => {
    return note.key === key;
  })?.value;
};

export const getRechargeNameValueProp = (
  collection: NameValueProp[],
  key: string
): string | undefined => {
  return collection.find((note) => {
    return note.name === key;
  })?.value;
};

export const getBasicFourSixtyscript = () => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.async = true;
  script.setAttribute("data-feed-id", "bloomsybox");
  script.setAttribute("data-open-links-in-same-page", "true");
  script.setAttribute("data-connector-filter", "47356,47357");
  return script;
};

type SortDirection = "asc" | "desc";

export const unsortedProducts = (
  a: ProductFieldsShortFragment | PromotionalCardFieldsFragment,
  b: ProductFieldsShortFragment |PromotionalCardFieldsFragment,
  direction: SortDirection
) => 0;
export const sortProductsByPrice = (
  a: ProductFieldsShortFragment | PromotionalCardFieldsFragment,
  b: ProductFieldsShortFragment | PromotionalCardFieldsFragment,
  direction: SortDirection
) => {
  const priceA = a.shopifyProduct?.priceRange?.minVariantPrice?.amount ?? 0;
  const priceB = b.shopifyProduct?.priceRange?.minVariantPrice?.amount ?? 0;
  if (direction === "asc") {
    return priceA - priceB;
  }
  return priceB - priceA;
};

export const productSortFnMap = {
  unsorted: unsortedProducts,
  price: sortProductsByPrice,
};
