import React, { FC, useEffect } from "react";
import tw from "twin.macro";
import { useKeenSlider } from "keen-slider/react";
import { ProductFieldsFragment } from "src/generated/datocms-types";
import { UpSellingCard } from "../Cart/CartDrawer/UpsellingCard";

type UpsellingSectionMobileType = {
  usProducts: ProductFieldsFragment[];
  inProductCard?: boolean;
};

export const UpsellingSectionMobile: FC<UpsellingSectionMobileType> = ({
  usProducts,
  inProductCard = false,
}) => {
  const [slideRef, slider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slidesPerView: 2.4,
    spacing: 6,
    loop: true,
  });

  useEffect(() => {
    if (slider) slider.refresh();
  }, [usProducts, slider]);

  return (
    <div
      ref={usProducts.length >= 3 ? slideRef : undefined}
      className={usProducts.length >= 3 ? "keen-slider" : ""}
      css={[
        usProducts.length < 3 && tw`flex items-center justify-start flex-wrap`,
        tw`h-[308px]`,
      ]}
    >
      {usProducts.map((p) => {
        return (
          <div
            key={`upselling-sectionCard-${p.id}`}
            className={usProducts.length >= 3 ? "keen-slider__slide" : ""}
            css={
              usProducts.length < 3 &&
              tw`w-[148px] !min-w-[148px] !max-w-[148px] mx-1`
            }
          >
            <UpSellingCard
              product={p}
              key={`upselling-sectionCard-${p.id}`}
              inProductCard={inProductCard}
            />
          </div>
        );
      })}
    </div>
  );
};
