import { FC } from "react";
import { Drawer as MuiDrawer } from "@mui/material";
import { DrawerProps as MuiDrawerProps } from "@mui/material/Drawer";
import { styled } from "twin.macro";

const StyledDrawer = styled(MuiDrawer)``;
interface DrawerProps extends MuiDrawerProps {}
export const Drawer: FC<DrawerProps> = (props) => {
  return <StyledDrawer {...props} />;
};
