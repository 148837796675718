/* eslint-disable react/function-component-definition */
import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  ProductFieldsCartFragment,
  ProductFieldsFragment,
  ProductFieldsShortFragment,
} from "src/generated/datocms-types";
import tw from "twin.macro";
import { getNameValueProp } from "src/shared/utils";
import ToggleWithLabel from "src/shared/components/ToggleWithLabel";
import { KeyValueProp } from "src/shared/types";
import { ShopifyLineItem, ShopifyVariantNode } from "src/shared/types/shopify";
import {
  AUTO_RENEW_PROP,
  RECIPIENT_EMAIL_PROP,
  GIFT_MESSAGE_MAX_LEN,
  GIFT_MESSAGE_PROP,
  IS_GIFT_PROP,
  RECIPIENT_NAME_PROP,
} from "../Cart/constants";
import { copy } from "./constants";
import {
  setLineItemProperties,
  removeLineItemProperty,
  updateGiftProperties,
} from "./utils";
import { getVariantFrequency } from "../Cart/utils";
import { StyledStepLabel } from "./styled";
import { isWeeklySuperGiftProduct } from "../../shared/utils/products";

type Props = {
  product:
    | ProductFieldsFragment
    | ProductFieldsShortFragment
    | ProductFieldsCartFragment;
  lineItem?: ShopifyLineItem;
  isGift: boolean;
  setIsGift: Dispatch<SetStateAction<boolean>>;
  giftEmail: string;
  setGiftEmail: Dispatch<SetStateAction<string>>;
  giftMessage: string;
  setGiftMessage: Dispatch<SetStateAction<string>>;
  setIsGiftMessageFormDirty: Dispatch<SetStateAction<boolean>>;
  isAutoRenew: boolean;
  setIsAutoRenew: Dispatch<SetStateAction<boolean>>;
  recipientName: string;
  setRecipientName: Dispatch<SetStateAction<string>>;
  isPrepay?: boolean;
  isSuperGift?: boolean;
  variant?: ShopifyVariantNode;
  setIsToggleChanged?: Dispatch<SetStateAction<boolean>> | undefined;
};
export const ProductGiftMessageForm: FC<Props> = ({
  product,
  lineItem,
  isGift,
  setIsGift,
  giftEmail,
  setGiftEmail,
  giftMessage,
  setGiftMessage,
  setIsGiftMessageFormDirty,
  isAutoRenew,
  setIsAutoRenew,
  recipientName,
  setRecipientName,
  isPrepay = false,
  isSuperGift = false,
  variant,
  setIsToggleChanged,
}) => {
  const variantFrequency = getVariantFrequency(variant?.title ?? "");
  useState<number>(0);
  let timer: number;
  const timeoutVal = 500;
  useEffect(() => {
    if (lineItem) {
      const giftMessageProp = getNameValueProp(
        lineItem?.attributes ?? [],
        GIFT_MESSAGE_PROP
      );
      const giftEmailProp = getNameValueProp(
        lineItem?.attributes ?? [],
        RECIPIENT_EMAIL_PROP
      );
      const isGiftProp = getNameValueProp(
        lineItem?.attributes ?? [],
        IS_GIFT_PROP
      );
      const autoRenewProp = getNameValueProp(
        lineItem?.attributes ?? [],
        AUTO_RENEW_PROP
      );
      const recipientNameProp = getNameValueProp(
        lineItem?.attributes ?? [],
        RECIPIENT_NAME_PROP
      );
      if (isGiftProp) {
        setIsGift(true);
        if (autoRenewProp) setIsAutoRenew(true);
      }
      if (giftMessageProp) setGiftMessage(giftMessageProp);
      if (giftEmailProp) setGiftEmail(giftEmailProp);
      if (recipientNameProp) setRecipientName(recipientNameProp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const toggleAutoRenew = (isChecked: boolean) => {
    if (setIsToggleChanged) {
      setIsToggleChanged(true);
    }

    setIsAutoRenew(isChecked);
    if (!lineItem) return;
    let newProps: KeyValueProp[];
    if (lineItem.attributes) {
      if (isChecked) {
        newProps = setLineItemProperties(
          AUTO_RENEW_PROP,
          "true",
          lineItem.attributes
        );
      } else {
        newProps = removeLineItemProperty(AUTO_RENEW_PROP, lineItem.attributes);
      }
      updateGiftProperties(lineItem, newProps);
    }
  };
  const saveGiftDetails = () => {
    const hasGiftMessage = giftMessage.length > 0;
    if (lineItem?.attributes) {
      if (!hasGiftMessage) {
        const newProps = removeLineItemProperty(
          RECIPIENT_NAME_PROP,
          removeLineItemProperty(
            IS_GIFT_PROP,
            removeLineItemProperty(
              RECIPIENT_EMAIL_PROP,
              removeLineItemProperty(GIFT_MESSAGE_PROP, lineItem.attributes)
            )
          )
        );
        setGiftEmail("");
        setGiftMessage("");
        setRecipientName("");
        setIsGiftMessageFormDirty(false);
        updateGiftProperties(lineItem, newProps);
      } else {
        const newProps = setLineItemProperties(
          RECIPIENT_NAME_PROP,
          recipientName,
          setLineItemProperties(
            RECIPIENT_EMAIL_PROP,
            giftEmail,
            setLineItemProperties(
              GIFT_MESSAGE_PROP,
              giftMessage,
              setLineItemProperties(
                IS_GIFT_PROP,
                hasGiftMessage.toString(),
                lineItem.attributes
              )
            )
          )
        );
        updateGiftProperties(lineItem, newProps);
      }
    }
  };
  const handleKeyDown = () => {
    window.clearTimeout(timer);
  };
  const handleKeyUp = () => {
    window.clearTimeout(timer);
    timer = window.setTimeout(() => {
      saveGiftDetails();
    }, timeoutVal);
  };
  // fixme: remove `false` once we are sending gift recipient emails automatically
  const showGiftRecipient = product.isSubscription && false;
  return (
    <div css={tw`my-2`}>
      <div css={tw`pt-2`}>
        <a
          css={[
            tw`text-sm uppercase font-roboto tracking-wider text-bloomsy-black`,
          ]}
        >
          {copy.giftToggleLabel}
        </a>
      </div>

      <div>
        <div>
          <textarea
            css={tw`bg-slate-100 mt-1 block w-full hover:border-[#78bd72] font-roboto focus:(outline-none border-0 ring-4 ring-[#78bd72]) py-1 border-solid border-gray-300 rounded-md`}
            rows={2}
            maxLength={GIFT_MESSAGE_MAX_LEN}
            placeholder={copy.giftMessagePlaceholder}
            value={giftMessage}
            onChange={(e) => {
              setIsGift(e.currentTarget.value.length > 0);
              setGiftMessage(e.currentTarget.value);
              setIsGiftMessageFormDirty(true);
            }}
            onKeyDown={handleKeyDown}
            onKeyUp={handleKeyUp}
            // onBlur={saveGiftDetails}
          />
          <div css={tw`mt-1 ml-1 text-gray-400 text-xs`}>
            <div>
              {`${copy.giftMessageRemaning}: ${
                GIFT_MESSAGE_MAX_LEN - giftMessage.trim().length
              }`}
            </div>
          </div>
        </div>
        {showGiftRecipient && (
          <>
            <div css={tw`py-2`}>
              <label htmlFor="giftEmail">
                <StyledStepLabel css={tw`font-normal text-xs`}>
                  {copy.giftEmailLabel}
                </StyledStepLabel>
                <div css={tw`mt-1`}>
                  <input
                    id="giftEmail"
                    css={tw`hover:border-green-500 font-roboto focus:(outline-none border-0 ring-4 ring-green-500) py-1 border-solid border-gray-300 rounded-md w-full`}
                    type="email"
                    placeholder={copy.giftEmailPlaceholder}
                    onChange={(e) => {
                      setGiftEmail(e.currentTarget.value);
                      setIsGiftMessageFormDirty(true);
                    }}
                    value={giftEmail}
                  />
                  <div css={tw`mt-1 ml-1 text-gray-400 text-xs`}>
                    <div>{copy.giftEmailDetails}</div>
                  </div>
                </div>
              </label>
            </div>
            <div css={tw`py-2`}>
              <label htmlFor="recipientName">
                <StyledStepLabel css={tw`font-normal text-xs`}>
                  {copy.recipientNameLabel}
                </StyledStepLabel>
                <div css={tw`mt-1`}>
                  <input
                    id="recipientName"
                    css={tw`hover:border-green-500 font-roboto focus:(outline-none border-0 ring-4 ring-green-500) py-1 border-solid border-gray-300 rounded-md w-full`}
                    type="text"
                    placeholder={copy.recipientNamePlaceholder}
                    onChange={(e) => {
                      setRecipientName(e.currentTarget.value);
                      setIsGiftMessageFormDirty(true);
                    }}
                    value={recipientName}
                  />
                </div>
              </label>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
