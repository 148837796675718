import { addDays } from "date-fns";
import { format, OptionsWithTZ, utcToZonedTime } from "date-fns-tz";

import { DATE_FORMATS, EST_TIMEZONE } from "src/constants";

export type WEEKDAY = keyof typeof WEEKDAYS_MAP;
export type WEEKDAYS = WEEKDAY[];
type FixedDateTimestampOpts = {
  hour?: number;
  minutes?: number;
  seconds?: number;
  milliseconds?: number;
};
export const WEEKDAYS_MAP = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};
export const getNextWeekDate = (today: Date, weekday: WEEKDAY): Date => {
  const nextWeekDay = WEEKDAYS_MAP[weekday];
  const offsetDays = (nextWeekDay + (7 - (today.getDay() % 7))) % 7;
  return addDays(today, offsetDays);
};
export const matchesWeekDays = (date: Date, weekdays: WEEKDAYS) => {
  const weekday = Object.keys(WEEKDAYS_MAP)[date.getDay()] as WEEKDAY;
  return weekdays.includes(weekday);
};
export const setDateTime = (dt: Date, opts?: FixedDateTimestampOpts) => {
  const config = { hour: 0, minutes: 0, seconds: 0, milliseconds: 0, ...opts };
  dt.setHours(config.hour);
  dt.setMinutes(config.minutes);
  dt.setSeconds(config.seconds);
  dt.setMilliseconds(config.milliseconds);
  return dt;
};
export const formatDate = (
  date: Date,
  dateFormat: string,
  opts?: OptionsWithTZ
) => {
  return opts ? format(date, dateFormat, opts) : format(date, dateFormat);
};
export const formatDateRange = (
  startDate: Date,
  endDate: Date
): { display: string; startDt?: string; endDt?: string } => {
  const startDt = format(startDate, DATE_FORMATS.default);
  const endDt = format(endDate, DATE_FORMATS.default);
  if (startDate.getMonth() === endDate.getMonth()) {
    const month = format(startDate, "MMM");
    const year = format(startDate, "yyyy");
    const start = format(startDate, "dd");
    const end = format(endDate, "dd");
    return {
      display: `${month} ${start}–${end}, ${year}`,
      endDt,
      startDt,
    };
  }
  return {
    display: `${startDt}–${endDt}`,
    endDt,
    startDt,
  };
};
export const timezoneDate = (date: Date | string, tz?: string) => {
  let _date;
  if (typeof date === "string") {
    _date = new Date(date);
  } else {
    _date = date;
  }
  return utcToZonedTime(_date, tz ?? EST_TIMEZONE);
};
