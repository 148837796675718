import React, { FC, Fragment } from "react";
import Head from "next/head";
import PageHeader from "src/ui/components/PageHeader";
import PageFooter from "src/ui/components/PageFooter";
import {
  GetLayoutDataQuery,
  MetaTagsFragment,
} from "src/generated/datocms-types";
import { CartDrawer } from "src/features/Cart/CartDrawer";
import { renderMetaTags } from "src/shared/utils";
import { SimpleHeader } from "src/ui/components/SimpleHeader";
import { PreviewNotice } from "./PreviewNotice";

type LayoutProps = {
  meta?: MetaTagsFragment[];
  layoutData: GetLayoutDataQuery;
  preview?: boolean;
  showPageHeader?: boolean;
  showPageFooter?: boolean;
  pageHeaderType?: string;
  hasLogo?: boolean;
  urlLogo?: string;
};

// eslint-disable-next-line react/function-component-definition
export const Layout: FC<LayoutProps> = ({
  layoutData,
  meta,
  children,
  preview = false,
  showPageHeader = true,
  showPageFooter = true,
  pageHeaderType = "Menu",
  hasLogo = false,
  urlLogo = "",
}) => {
  return (
    <Fragment>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {layoutData.site.faviconMetaTags &&
          renderMetaTags(layoutData.site.faviconMetaTags)}
        {meta && renderMetaTags(meta)}
      </Head>
      {pageHeaderType === "Simple"
        ? showPageHeader && <SimpleHeader hasLogo={hasLogo} urlLogo={urlLogo} />
        : showPageHeader && <PageHeader data={layoutData} />}

      {preview && <PreviewNotice />}
      <CartDrawer />
      <main>{children}</main>
      {showPageFooter && <PageFooter data={layoutData.footer} />}
    </Fragment>
  );
};
