/* eslint-disable react/function-component-definition */
import React from "react";
import tw from "twin.macro";
import { HiCheck } from "react-icons/hi";
import { IoLocationOutline } from "react-icons/io5";
import BloomsyLoader from "src/shared/components/BloomsyLoader";
import { StyledStepLabel } from "./styled";
import { copy } from "./constants";

type Props = {
  zipCode: string;
  setZipCode: (zipCode: string) => void;
  zipCodeData: { valid: boolean } | undefined;
  isZipCodeLoading: boolean;
  isZipCodeFetching: boolean;
  isZipCodeError: boolean;
  isSaturdayZipCodeVerified: boolean;
  deliveryDate: string;
};

export const SaturdayZipCodeForm = ({
  zipCode,
  setZipCode,
  zipCodeData,
  isZipCodeLoading,
  isZipCodeFetching,
  isZipCodeError,
  isSaturdayZipCodeVerified,
  deliveryDate,
}: Props) => {
  return (
    <div>
      <StyledStepLabel css={tw`mb-2`}>
        <div css={tw`w-full flex flex-row items-center`}>
          <div css={tw`flex items-center`}>
            <span css={tw`mr-1`}>
              {isSaturdayZipCodeVerified ? <HiCheck /> : <IoLocationOutline />}
            </span>
            <span>{copy.zipCodeLabel}</span>
          </div>
        </div>
      </StyledStepLabel>
      {!isZipCodeLoading && !isZipCodeFetching && (
        <div>
          <div css={tw`py-2 flex gap-4 relative`}>
            <IoLocationOutline
              css={tw`absolute right-4 h-4 w-4 top-1/2 -translate-y-1/2 mt-0.5`}
            />
            <input
              css={tw`mt-1 block w-full hover:border-green-500 font-roboto focus:(outline-none border-0 ring-4 ring-green-500) py-1.5 border-solid border-gray-300 rounded-md`}
              placeholder={copy.zipCodePlaceholder}
              type="text"
              value={zipCode}
              onChange={(e) => {
                if (e.target.value.length <= 5) {
                  setZipCode(e.target.value);
                }
              }}
            />
          </div>
          {!zipCodeData && !isZipCodeError && (
            <p css={tw`font-normal text-sm`}>{copy.zipCodeExplain}</p>
          )}
        </div>
      )}
      {(isZipCodeLoading || isZipCodeFetching) && (
        <div css={tw`flex items-center gap-2 my-2 justify-center`}>
          <BloomsyLoader css={tw`w-6 h-6`} />
          <span css={tw`font-normal text-sm`}>{copy.zipCodeValidating}</span>
        </div>
      )}
      {!isZipCodeError && isSaturdayZipCodeVerified && (
        <p css={tw`text-green-700 text-sm`}>{copy.verifyZipCodeSuccess}</p>
      )}
      {isZipCodeError && (
        <p css={tw`text-bloomsy-red text-sm`}>{copy.verifyZipCodeError}</p>
      )}
      {!isZipCodeError &&
        !isSaturdayZipCodeVerified &&
        zipCodeData &&
        deliveryDate && (
          <p css={tw`text-bloomsy-red text-sm`}>{copy.verifyInvalidZipCode}</p>
        )}
    </div>
  );
};
