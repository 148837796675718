/* eslint-disable react/function-component-definition */
import React, { FC, useRef, useCallback } from "react";
import tw, { theme as twTheme } from "twin.macro";
import { addMinutes, format, isSaturday } from "date-fns";
import { useMediaQuery } from "@mui/material";
import { MdDateRange } from "react-icons/md";
import {
  dateMatches,
  DatePickerClientConfigT,
  DisabledDate,
  loadDatePickerConfig,
} from "src/features/ProductForm/utils";
import {
  ProductFieldsCartFragment,
  ProductFieldsFragment,
} from "src/generated/datocms-types";
import { useQuery } from "react-query";
import { renderDate } from "src/shared/helpers";
import { SaturdayZipCodeForm } from "src/features/ProductForm/SatudayZipCodeForm";
import { useVerifyZipCode } from "src/features/ProductForm/hooks";
import { DatePicker, DatePickerInput } from "@mantine/dates";
import { NextDayDeliveryConfig } from "src/shared/types";
import { cartBusy } from "../state";

export type Props = {
  label: string;
  product: ProductFieldsFragment | ProductFieldsCartFragment;
  onChange: (date: string) => void;
  disabledDates?: DisabledDate[];
  initialDate?: Date;
  openSlideCalendar?: string;
  handleOpenOrCloseSlideCalendar?: (productId: string) => void;
  variant?: "full" | "drawer";
};
export const DeliveryDatePicker: FC<Props> = ({
  label,
  onChange,
  product,
  disabledDates = [],
  initialDate = null,
  openSlideCalendar,
  handleOpenOrCloseSlideCalendar,
  variant = "full",
}) => {
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const selectedDate = useRef<Date | null>(initialDate);
  const previousSelectedDate = useRef<Date | null>(initialDate);
  const [isSaturdayZipCodeVerified, setIsSaturdayZipCodeVerified] =
    React.useState(false);
  const [zipCode, setZipCode] = React.useState("");
  const [isZipCodeError, setIsZipCodeError] = React.useState(false);
  const [isValidZipCode, setIsValidZipCode] = React.useState(true);
  const {
    isLoading: isZipCodeLoading,
    isError: isVerifyZipCodeError,
    refetch: zipCodeRefetch,
    isFetching: isZipCodeFetching,
    data: zipCodeData,
  } = useVerifyZipCode(
    zipCode,
    true,
    format(selectedDate.current as Date, "MM-dd-yyyy")
  );
  React.useEffect(() => {
    setIsSaturdayZipCodeVerified(false);
    const refetchZipCode = async () => {
      const response = await zipCodeRefetch();
      if (!isZipCodeError) {
        if (response?.data?.valid) {
          cartBusy(true);
          setIsSaturdayZipCodeVerified(true);
          setTimeout(() => {
            onChange(format(selectedDate.current as Date, "MM-dd-yyyy"));
            setIsValidZipCode(true);
          }, 2500);
        } else {
          selectedDate.current = previousSelectedDate.current;
          setIsSaturdayZipCodeVerified(false);
          cartBusy(true);
          setTimeout(() => {
            setZipCode("");
            setIsValidZipCode(true);
            cartBusy(false);
          }, 2500);
        }
      }
    };
    if (zipCode.length === 5) {
      refetchZipCode();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zipCode]);
  React.useEffect(() => {
    if (isVerifyZipCodeError) {
      setIsZipCodeError(true);
      setZipCode("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVerifyZipCodeError]);
  React.useEffect(() => {
    if (
      openSlideCalendar &&
      handleOpenOrCloseSlideCalendar &&
      isValidZipCode &&
      isSaturdayZipCodeVerified
    ) {
      handleOpenOrCloseSlideCalendar("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidZipCode, isSaturdayZipCodeVerified]);
  const { isSubscription, id: productId } = product;
  const query = useQuery<DatePickerClientConfigT>(
    [{ productId, isSubscription }, "datepicker"],
    () =>
      loadDatePickerConfig({
        productId,
        isSubscription,
        userDate: new Date().toISOString(),
      })
  );
  const shouldDisableDate = useCallback(
    (date: Date | null) => {
      const month = date!.getMonth() + 1;
      const day = date!.getDate();
      const result = query.data?.shouldDisableDate(date) ?? false;
      const override = dateMatches(month, day, disabledDates);
      return override || result;
    },
    [query.data, disabledDates]
  );
  const handleOnClose = useCallback(() => {
    if (!selectedDate.current) return;
    if (
      isSaturday(selectedDate.current as Date) &&
      isSaturday(previousSelectedDate.current as Date) &&
      isValidZipCode
    ) {
      onChange(format(selectedDate.current as Date, "MM-dd-yyyy"));
      previousSelectedDate.current = selectedDate.current;
    }
    if (
      isSaturday(selectedDate.current as Date) &&
      !isSaturday(previousSelectedDate.current as Date) &&
      selectedDate.current.getDate() !== previousSelectedDate.current?.getDate()
    ) {
      setIsValidZipCode(false);
    } else {
      setZipCode("");
      setIsValidZipCode(true);
      onChange(format(selectedDate.current as Date, "MM-dd-yyyy"));
      previousSelectedDate.current = selectedDate.current;
    }
  }, [isValidZipCode, onChange]);
  const handleOnChange = useCallback(
    (date: Date) => {
      selectedDate.current = date;
      handleOnClose();

      if (
        openSlideCalendar &&
        handleOpenOrCloseSlideCalendar &&
        (!isSaturday(selectedDate.current as Date) ||
          (isValidZipCode && isSaturdayZipCodeVerified))
      )
        handleOpenOrCloseSlideCalendar("");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedDate.current, openSlideCalendar, isValidZipCode]
  );
  if (query.error) {
    return (
      <div css={tw`text-sm font-roboto text-red-700`}>
        Something went wrong, please refresh the page and try again.
      </div>
    );
  }
  if (query.isLoading) {
    return <div css={tw`text-sm font-roboto`}>Loading delivery date...</div>;
  }
  const datePickerConfig = query.data ?? ({} as DatePickerClientConfigT);
  if (!datePickerConfig) {
    return (
      <div css={tw`text-sm font-roboto`}>
        An error occurred, please refresh the page and try again.
      </div>
    );
  }

  const minDate = addMinutes(
    new Date(datePickerConfig.minDate),
    new Date().getTimezoneOffset()
  );
  const maxDate = addMinutes(
    new Date(datePickerConfig.maxDate),
    new Date().getTimezoneOffset()
  );

  const nextDayDeliveryConfig: NextDayDeliveryConfig = {
    enabled: datePickerConfig.nextDayShippingConfig.enabled,
    isExpired: datePickerConfig.nextDayShippingConfig.isExpired,
    isExpedited: datePickerConfig.nextDayShippingConfig.isExpedited,
  };

  return (
    <div>
      <div>
        <div>
          {variant === "full" && (
            <>
              <div>{`${label}:`}</div>
              <div>
                <DatePickerInput
                  inputMode="text"
                  dropdownType={isDesktop ? "popover" : "modal"}
                  placeholder="Select delivery date"
                  minDate={minDate}
                  maxDate={maxDate}
                  excludeDate={shouldDisableDate}
                  value={selectedDate.current}
                  onChange={handleOnChange}
                  maxLevel="month"
                  firstDayOfWeek={0}
                  valueFormat="MMMM D, YYYY"
                  size="md"
                  rightSection={<MdDateRange css={tw`text-gray-300`} />}
                  modalProps={{
                    styles: {
                      overlay: {
                        zIndex: 5000,
                      },
                      inner: {
                        zIndex: 6000,
                      },
                    },
                  }}
                  popoverProps={{
                    styles: {
                      dropdown: {
                        boxShadow:
                          "0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 11px rgba(0, 0, 0, 0.18)",
                      },
                    },
                  }}
                  styles={{
                    day: {
                      minHeight: "50px",
                      minWidth: "38px",
                      borderRadius: "0%",
                      "&[data-weekend]": {
                        color: "black",
                      },
                      "&[data-selected], &[data-selected]:hover": {
                        backgroundColor: "#0e9f6e",
                        color: "white",
                      },
                    },
                    input: tw`border-green-500 hover:border-green-500 font-roboto text-base focus:(outline-none ring-4 ring-green-500)`,
                  }}
                  renderDay={(date) =>
                    renderDate(
                      date,
                      nextDayDeliveryConfig,
                      minDate,
                      maxDate,
                      shouldDisableDate,
                      product
                    )
                  }
                />

                {!isValidZipCode && (
                  <div css={tw`mt-5`}>
                    <SaturdayZipCodeForm
                      isSaturdayZipCodeVerified={isSaturdayZipCodeVerified}
                      zipCode={zipCode}
                      setZipCode={setZipCode}
                      isZipCodeError={isZipCodeError}
                      zipCodeData={zipCodeData}
                      isZipCodeLoading={isZipCodeLoading}
                      isZipCodeFetching={isZipCodeFetching}
                      deliveryDate={format(
                        selectedDate.current as Date,
                        "MM-dd-yyyy"
                      )}
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {variant === "drawer" && (
            <>
              <DatePicker
                minDate={minDate}
                maxDate={maxDate}
                excludeDate={shouldDisableDate}
                defaultDate={selectedDate.current ?? minDate}
                value={selectedDate.current}
                onChange={handleOnChange}
                maxLevel="month"
                firstDayOfWeek={0}
                size="xl"
                monthLabelFormat="MMMM"
                styles={{
                  day: {
                    minHeight: "50px",
                    minWidth: "38px",
                    borderRadius: "0%",
                    "&[data-weekend]": {
                      color: "black",
                    },
                    "&[data-selected], &[data-selected]:hover": {
                      backgroundColor: "#0e9f6e",
                      color: "white",
                    },
                    fontSize: "16px",
                    lineHeight: "28px",
                    fontFamily: "Roboto",
                  },
                  calendarHeader: {
                    justifyContent: "center",
                  },
                  calendarHeaderLevel: {
                    fontSize: "30px",
                    lineHeight: "38px",
                    fontFamily: "Roboto",
                    flex: "none",
                    width: "38%",
                  },
                  calendarHeaderControl: {
                    "& svg": {
                      width: "2rem !important",
                      height: "2rem  !important",
                    },
                  },
                }}
                renderDay={(date) =>
                  renderDate(
                    date,
                    nextDayDeliveryConfig,
                    minDate,
                    maxDate,
                    shouldDisableDate,
                    product
                  )
                }
              />

              {!isValidZipCode && (
                <div css={tw`mt-5 py-2 px-4`}>
                  <SaturdayZipCodeForm
                    isSaturdayZipCodeVerified={isSaturdayZipCodeVerified}
                    zipCode={zipCode}
                    setZipCode={setZipCode}
                    isZipCodeError={isZipCodeError}
                    zipCodeData={zipCodeData}
                    isZipCodeLoading={isZipCodeLoading}
                    isZipCodeFetching={isZipCodeFetching}
                    deliveryDate={format(
                      selectedDate.current as Date,
                      "MM-dd-yyyy"
                    )}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
