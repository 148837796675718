/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/function-component-definition */
import {
  FC,
  ReactNode,
  MouseEvent as ReactMouseEvent,
  SyntheticEvent,
  useState,
  forwardRef,
  useEffect,
  useCallback,
} from "react";
import { styled } from "@mui/material/styles";
import tw, { TwStyle, theme as twTheme } from "twin.macro";
import { Drawer } from "src/shared/components/Drawer";
import { IconButton } from "src/shared/components/IconButton";
import { MdMenu } from "react-icons/md";
import {
  CartIcon,
  Logo,
  LogoText,
  UserIcon,
  PlusIcon,
  MinusIcon,
} from "src/shared/components/Icons";
import { useRouter } from "next/router";
import NextLink from "next/link";
import { useCartCount, useCartVisibility } from "src/features/Cart/state";

import {
  GetLayoutDataQuery,
  MenuLinksFragment,
  LinkCollectionItemRecord,
  SubMenuSettingFragment,
} from "src/generated/datocms-types";
import { slugsPrefixMap } from "src/services/datocms/constants";
import { TopBarNotice } from "src/features/Layout/TopBarNotice";
import { LOGIN_URL } from "src/features/LoginPage/utils";
import {
  Paper,
  Tabs,
  Tab,
  Popper,
  Accordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  PopperProps,
  useMediaQuery,
  Slide,
} from "@mui/material";
import { FiChevronDown } from "react-icons/fi";
import { withStyles } from "tss-react/mui";
import PageMegaMenu from "../components/PageMegaMenu";

const PREFIX = "PageHeader";

const classes = {
  root: `${PREFIX}-root`,
  content: `${PREFIX}-content`,
  expanded: `${PREFIX}-expanded`,
  root2: `${PREFIX}-root2`,
};

const Root = styled("header")(({ theme }) => ({
  [`& .${classes.root}`]: {
    minHeight: 36,
    padding: theme.spacing(0),
    height: 36,
    "&$expanded": {
      minHeight: 36,
    },
  },

  [`& .${classes.content}`]: { margin: "0px" },
  [`& .${classes.expanded}`]: { margin: "0" },

  [`& .${classes.root2}`]: {
    padding: theme.spacing(0),
  },
}));

type MobileDrawerProps = {
  isOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  children?: ReactNode;
};
type NavLinkProps = {
  isActive?: boolean;
  isExternal?: boolean;
  href?: string;
  children: ReactNode;
  onClick?: (event: ReactMouseEvent<Element, MouseEvent>) => void;
  onMouseEnter?: (event: ReactMouseEvent<Element, MouseEvent>) => void;
  css?: TwStyle;
};
type SubMenuAccordionProps = {
  menuLink?: MenuLinksFragment;
  subItem?: string;
  id?: string;
  children: ReactNode;
};
type MobileSubMenuProps = {
  subMenuSetting: SubMenuSettingFragment;
  menuLink: MenuLinksFragment;
};
type SubMenuProps = {
  menuItem: LinkCollectionItemRecord[];
  subMenuProps: SubMenuStateProps;
};
type PageHeaderProps = {
  data: GetLayoutDataQuery;
};
export type SubMenuStateProps = {
  setSubMenuOpen: (status: boolean) => void;
  subMenuOpen: boolean;
  anchorEl: PopperProps["anchorEl"];
  tabName: string;
};

// new types
type SubMenuSlideProps = {
  id: string;
  title: string;
  subMenus: SubMenuSettingFragment;
  activeItemId: string;
  handleSlideBackMenu: () => void;
  handleClickNavLink: () => void;
};
type MobileSubMenuSlideProps = {
  menuLink: MenuLinksFragment;
  handleSlideMenu: (idItem: string) => void;
};

// components
const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  const {
    isActive = false,
    isExternal = false,
    children,
    href,
    ...otherProps
  } = props;
  const externalProps = isExternal
    ? {
        rel: "noopener noreferrer",
        target: "_blank",
      }
    : {};
  const hrefProp = href ? { href } : {};
  return (
    <a
      css={[
        isActive ? tw`text-bloomsy-darkred` : tw`text-inherit`,
        tw`block py-2 font-roboto md:font-roboto lg:(py-4 font-roboto) tracking-wider hover:text-bloomsy-red`,
      ]}
      ref={ref}
      {...hrefProp}
      {...externalProps}
      {...otherProps}
    >
      {children}
    </a>
  );
});

export const NavLinkItem: FC<{
  data: MenuLinksFragment;
  css?: TwStyle;
  handleClickNavLink?: () => void | undefined;
}> = ({ data, handleClickNavLink, ...otherProps }) => {
  const router = useRouter();
  const { title, url, isExternal, internalLink } = data;
  let href = url ?? "#";

  if (isExternal) {
    return (
      <NavLink
        {...otherProps}
        href={href}
        isExternal
        onClick={handleClickNavLink}
      >
        {title}
      </NavLink>
    );
  }
  if (internalLink?.slug) {
    const { slug } = internalLink;
    href = `${
      slugsPrefixMap[internalLink?._modelApiKey as keyof typeof slugsPrefixMap]
    }${slug}`;
  }
  const isActive = href === router.asPath;

  return (
    <NextLink href={href} passHref legacyBehavior>
      <NavLink {...otherProps} isActive={isActive} onClick={handleClickNavLink}>
        {title}
      </NavLink>
    </NextLink>
  );
};
const AccordionSummary = withStyles(MuiAccordionSummary, {
  root: {
    minHeight: 36,
    padding: 0,
    height: 36,
    "&$expanded": {
      minHeight: 36,
    },
  },
  content: { margin: "0px" },
  expanded: { margin: "0" },
});
const AccordionDetails = withStyles(MuiAccordionDetails, {
  root: {
    padding: 0,
  },
});
const MobileDrawer: FC<MobileDrawerProps> = ({
  isOpen,
  openDrawer,
  closeDrawer,
  children,
}) => {
  return (
    <div css={tw`block lg:hidden w-2/12`}>
      <IconButton onClick={openDrawer} size="large">
        <MdMenu />
      </IconButton>
      <Drawer
        open={isOpen}
        anchor="left"
        onClose={closeDrawer}
        PaperProps={{ style: { width: "90%", backgroundColor: "#FAF8F5" } }}
      >
        <div css={tw`flex justify-center p-4 bg-white`}>
          <Logo css={tw`text-bloomsy-red w-10 h-10`} />
        </div>
        {children}
      </Drawer>
    </div>
  );
};
const SubMenuAccordion: FC<SubMenuAccordionProps> = ({
  menuLink,
  subItem,
  id,
  children,
}) => {
  const [activeItemId, setActiveItemId] = useState<string>("");
  const idItem = menuLink?.id || id;
  const isItemExpanded = activeItemId === idItem;

  return (
    <Accordion
      key={idItem}
      id={idItem}
      expanded={isItemExpanded}
      onChange={(e, isExpanded) => setActiveItemId(isExpanded ? idItem : "")}
      css={[tw`font-roboto normal-case cursor-pointer shadow-none`]}
    >
      <AccordionSummary
        expandIcon={
          isItemExpanded ? (
            <MinusIcon css={tw`w-6 h-6 p-0 text-black`} />
          ) : (
            <PlusIcon css={tw`w-6 h-6 p-0  text-black`} />
          )
        }
        css={[tw`capitalize focus:outline-none`]}
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
        }}
      >
        {menuLink ? (
          <div>
            <NavLinkItem css={tw`uppercase py-0 `} data={menuLink!} />
          </div>
        ) : (
          <span css={tw`uppercase block font-roboto tracking-wider  `}>
            {subItem}
          </span>
        )}
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: classes.root2,
        }}
      >
        <div>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};
const MobileSubMenu: FC<MobileSubMenuProps> = ({
  subMenuSetting,
  menuLink,
}) => {
  const { middleSubMenuItem, leftTitle } = subMenuSetting;
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      <SubMenuAccordion subItem={menuLink.title || ""}>
        {leftTitle && (
          <NavLinkItem
            css={tw`uppercase ml-1 font-bold text-xs`}
            data={leftTitle[0]!}
          />
        )}
        {middleSubMenuItem?.length && (
          <>
            {middleSubMenuItem.map((ms) => {
              if (!ms.item?.length) {
                return null;
              }
              return (
                <div key={ms.id} css={[!ms.title && tw`-mt-5`]}>
                  <span css={tw`ml-1 inline-block pt-2 pb-1 font-bold text-xs`}>
                    {ms.title}
                  </span>
                  {ms.item.map((subI) => {
                    if (!subI) return null;
                    return (
                      <NavLinkItem
                        key={subI.id}
                        css={tw`py-1 px-2`}
                        data={subI!}
                      />
                    );
                  })}
                </div>
              );
            })}
          </>
        )}
      </SubMenuAccordion>
    </>
  );
};
const SubMenu: FC<SubMenuProps> = ({ menuItem, subMenuProps }) => {
  const { tabName, anchorEl, setSubMenuOpen, subMenuOpen } = subMenuProps;

  const currentItemTab = menuItem.find((item) => {
    return item?.title?.toLowerCase().trim() === tabName.toLowerCase().trim();
  }) as LinkCollectionItemRecord;

  const handleMenuClose = () => {
    setSubMenuOpen(false);
  };

  useEffect(() => {
    if (!currentItemTab || currentItemTab.subMenuItem.length <= 0) {
      setSubMenuOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentItemTab]);

  if (!menuItem) {
    return null;
  }
  if (!currentItemTab || currentItemTab.subMenuItem.length <= 0) {
    return null;
  }
  return (
    <Popper
      className="popper-root"
      open={subMenuOpen}
      anchorEl={anchorEl}
      id="menu-list-grow"
      placement="bottom"
      css={[tw`w-full z-50`, { left: "-5px !important" }]}
      onMouseLeave={() => handleMenuClose()}
    >
      <Paper key={currentItemTab.id}>
        <div css={tw`bg-bloomsy-white flex justify-center `}>
          <PageMegaMenu data={currentItemTab.subMenuItem[0]} />
        </div>
      </Paper>
    </Popper>
  );
};

// new components

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Arrow = ({ left }: { left: boolean }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      css={tw`w-[13px] h-[13px] fill-[#4D5562]`}
    >
      {left && (
        <path
          d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
      {!left && (
        <path
          d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z"
          stroke="#4D5562"
          strokeWidth={2}
        />
      )}
    </svg>
  );
};

const SubMenuSlide: FC<SubMenuSlideProps> = ({
  id,
  title,
  subMenus,
  activeItemId,
  handleSlideBackMenu,
  handleClickNavLink,
}) => {
  const { middleSubMenuItem, leftTitle } = subMenus;

  return (
    <Slide in={activeItemId === id} direction="right">
      <div
        css={tw`absolute top-0 z-10 w-full h-full bg-white block font-roboto tracking-wider -translate-x-full`}
      >
        <div
          css={tw`flex justify-between items-center h-16 pt-3 bg-[#FAF8F500] px-4 mb-4 text-center text-base font-bold uppercase`}
          onClick={handleSlideBackMenu}
        >
          <span>
            <Arrow left />
          </span>
          <span>{title}</span>
          <span css={tw`w-[13px] h-[13px]`} />
        </div>
        <div css={tw`px-4`}>
          {leftTitle && (
            <div
              css={tw`my-3 flex items-center h-10 text-sm font-bold tracking-wider`}
            >
              <NavLinkItem
                css={tw`w-full`}
                data={leftTitle[0]!}
                handleClickNavLink={handleClickNavLink}
              />
            </div>
          )}
          {middleSubMenuItem?.length && (
            <>
              {middleSubMenuItem.map((ms) => {
                if (!ms.item?.length) {
                  return null;
                }
                return (
                  <div key={ms.id} css={[!ms.title]}>
                    <span
                      css={tw`inline-block text-sm font-bold uppercase h-10 flex items-center text-[#A8A8A8]`}
                    >
                      {ms.title}
                    </span>
                    {ms.item.map((subI) => {
                      if (!subI) return null;
                      return (
                        <div
                          key={subI.id}
                          css={tw`my-3 text-base flex items-center h-10 tracking-normal`}
                        >
                          <NavLinkItem
                            data={subI!}
                            css={tw`w-full`}
                            handleClickNavLink={handleClickNavLink}
                          />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    </Slide>
  );
};
const MobileSubMenuSlide: FC<MobileSubMenuSlideProps> = ({
  menuLink,
  handleSlideMenu,
}) => {
  const { title } = menuLink;
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      css={tw`flex justify-between items-center h-10`}
      onClick={() => {
        handleSlideMenu(menuLink.id);
      }}
    >
      <span>{title}</span>
      <span css={tw`mr-5`}>
        <Arrow left={false} />
      </span>
    </div>
  );
};

// root component
const PageHeader: FC<PageHeaderProps> = ({ data }) => {
  const router = useRouter();
  const cartCount = useCartCount();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, toggleCart] = useCartVisibility();
  const openCart = (event: ReactMouseEvent<Element, MouseEvent>) => {
    event.preventDefault();
    toggleCart();
  };
  const accountPageUrl = LOGIN_URL;
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"]>(null);
  const [tabValue, setTabValue] = useState(0);
  const [tabName, setTabName] = useState("");
  const handleMenuOpen = (
    index: number,
    currentTarget: SyntheticEvent["currentTarget"],
    name: string
  ) => {
    setAnchorEl(currentTarget);
    setSubMenuOpen(true);
    setTabValue(index);
    setTabName(name);
  };
  const subMProp: SubMenuStateProps = {
    setSubMenuOpen,
    subMenuOpen,
    anchorEl,
    tabName,
  };
  const [isHeaderShrunk, setIsHeaderShrunk] = useState(false);
  const isDesktop = useMediaQuery(`(min-width: ${twTheme`screens.lg`})`);
  const [y, setY] = useState(0);
  const [isScrollUp, setIsScrollUp] = useState(false);
  const handleScrollHeader = useCallback(() => {
    const element = document.getElementById("js-pageHeader");
    if (element) {
      const { height } = element.getBoundingClientRect();
      const { scrollTop } = document.documentElement;
      if (scrollTop > y) {
        setIsScrollUp(false);
      } else if (scrollTop < y) {
        setIsScrollUp(true);
      }
      setY(scrollTop);
      if (isDesktop && scrollTop > 90) {
        setIsHeaderShrunk(true);
      } else if (isDesktop && isScrollUp && scrollTop <= height - 10) {
        setIsHeaderShrunk(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [y, isScrollUp]);

  const [isOpen, setIsOpen] = useState(false);
  const [activeItemId, setActiveItemId] = useState<string>("");

  useEffect(() => {
    if (isDesktop) setIsOpen(false);
  }, [isDesktop]);

  useEffect(() => {
    document.addEventListener("scroll", handleScrollHeader);
    return () => {
      document.removeEventListener("scroll", handleScrollHeader);
    };
  }, [handleScrollHeader]);

  useEffect(() => {
    setY(window.scrollY);
    setIsHeaderShrunk(false);
  }, []);

  const isStickyAnnouncement = data.header?.isStickyAnnouncement ?? false;
  const displayAnnouncement = isStickyAnnouncement ? true : !isHeaderShrunk;

  const closeDrawer = () => {
    setIsOpen(false);
    setActiveItemId("");
  };
  const openDrawer = () => setIsOpen(true);
  const handleSlideMenu = (idItem: string) => {
    setActiveItemId(idItem);
  };
  const handleSlideBackMenu = () => {
    setActiveItemId("");
  };
  const handleOpenCartMenuSlide = (
    event: ReactMouseEvent<Element, MouseEvent>
  ) => {
    setIsOpen(false);
    openCart(event);
  };
  const handleClickNavLink = () => {
    closeDrawer();
  };

  return (
    <Root
      id="js-pageHeader"
      css={[
        tw`bg-white border-b border-solid border-gray-300 sticky z-20`,
        isHeaderShrunk && isDesktop && { top: "0px" },
        !isDesktop && { top: "0px" },
      ]}
    >
      {data.header?.announcementMessage && displayAnnouncement && (
        <TopBarNotice
          message={data.header.announcementMessage}
          theme={data.header.announcementTheme ?? undefined}
          linkText={data.header.announcementLinkText || ""}
          linkUrl={data.header.announcementLinkUrl || ""}
        />
      )}
      <nav
        css={[isDesktop ? tw`container px-4 sm:px-0` : tw`w-full mx-0 px-4`]}
      >
        <div
          id="js-topPageHeader"
          css={[
            tw`flex items-center justify-around lg:justify-end lg:pt-2 lg:pb-0 h-14 lg:h-16`,
            isHeaderShrunk && isDesktop && tw`absolute -top-32 opacity-0`,
            tw`transition-opacity duration-500 ease-out`,
          ]}
        >
          <MobileDrawer
            isOpen={isOpen}
            openDrawer={openDrawer}
            closeDrawer={closeDrawer}
          >
            <div css={tw`bg-white`}>
              {data?.header?.menuItem?.map((link) => {
                if (!link) return null;
                return (
                  <div
                    key={link.id}
                    css={tw`px-5 my-3 font-roboto text-sm font-bold tracking-wider uppercase`}
                  >
                    {link?.subMenuItem.length ? (
                      <div>
                        <MobileSubMenuSlide
                          menuLink={{
                            ...link,
                            shortDescription: null,
                            coverImage: null,
                          }}
                          handleSlideMenu={handleSlideMenu}
                        />
                      </div>
                    ) : (
                      <div css={tw`flex items-center h-10`}>
                        <NavLinkItem
                          css={tw`w-full`}
                          data={
                            {
                              ...link,
                              shortDescription: null,
                              coverImage: null,
                            }!
                          }
                          handleClickNavLink={handleClickNavLink}
                        />
                      </div>
                    )}
                  </div>
                );
              })}
              <div css={tw`mt-4 pt-6 bg-[#FAF8F5] uppercase font-bold text-sm`}>
                <div css={tw`px-4 my-3`}>
                  <NextLink href={accountPageUrl} passHref>
                    <NavLink>
                      <div css={tw`flex items-center justify-between h-8`}>
                        <div css={tw`flex items-center`}>
                          <UserIcon css={tw`text-bloomsy-red w-6 h-6`} />
                          <span css={tw`block ml-2`}>Sign In</span>
                        </div>
                        <span css={tw`mr-5`}>
                          <Arrow left={false} />
                        </span>
                      </div>
                    </NavLink>
                  </NextLink>
                </div>
                <div css={tw`px-4 my-3 mb-0`}>
                  <NavLink onClick={handleOpenCartMenuSlide} href="/cart">
                    <div css={tw`flex items-center justify-between h-8`}>
                      <div css={tw`flex items-center`}>
                        <CartIcon css={tw`text-bloomsy-red w-6 h-6`} />
                        <span css={tw`block ml-2`}>My Cart</span>
                      </div>
                      <span css={tw`mr-5`}>
                        <Arrow left={false} />
                      </span>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>

            {data?.header?.menuItem?.map((link) => {
              if (!link || link.subMenuItem.length === 0) return null;
              return (
                <SubMenuSlide
                  key={link.id}
                  id={link.id}
                  title={link.title ?? ""}
                  subMenus={link.subMenuItem[0] as SubMenuSettingFragment}
                  activeItemId={activeItemId}
                  handleSlideBackMenu={handleSlideBackMenu}
                  handleClickNavLink={handleClickNavLink}
                />
              );
            })}
          </MobileDrawer>
          <div
            css={[
              tw`lg:w-2/12 justify-start space-x-4 text-sm pl-10`,
              isHeaderShrunk || !isDesktop ? tw`hidden` : tw`lg:flex`,
            ]}
          />
          <div
            css={[
              tw`w-10/12 justify-center`,
              isHeaderShrunk && isDesktop ? tw`hidden` : tw`flex`,
            ]}
          >
            <NextLink href="/" passHref css={[tw`block`]}>
              <LogoText
                css={tw`w-auto text-bloomsy-red h-bloomsy-logo-sm lg:h-bloomsy-logo-lg`}
              />
            </NextLink>
          </div>
          <div
            css={[
              tw`flex space-x-2 sm:space-x-4 lg:space-x-6 w-2/12 justify-end pr-0 lg:pr-10`,
            ]}
          >
            <NavLink css={tw`hidden lg:block`} href={accountPageUrl}>
              <div css={tw`flex items-center`}>
                <UserIcon css={tw`text-bloomsy-red w-5 h-5`} />
              </div>
            </NavLink>
            {router.asPath !== "/cart" && (
              <NavLink onClick={openCart} href="/cart">
                <div css={tw`text-bloomsy-red flex space-x-1 sm:space-x-2`}>
                  <CartIcon css={tw`w-5 h-5`} />
                  <span css={tw`font-bold`}>{cartCount}</span>
                </div>
              </NavLink>
            )}
          </div>
        </div>
        <div css={[tw`flex items-center`, !isDesktop && tw`hidden`]}>
          <div css={tw`w-1/12 flex justify-center py-0`}>
            <NextLink
              href="/"
              passHref
              css={[
                tw`py-3 lg:py-0 flex justify-center w-0`,
                isHeaderShrunk && isDesktop && tw`w-full`,
              ]}
            >
              <Logo
                css={[
                  tw`w-auto text-bloomsy-red h-bloomsy-logo-sm lg:h-bloomsy-logo-lg opacity-0 transition-opacity duration-100 ease-in-out`,
                  isHeaderShrunk && isDesktop && tw`opacity-100`,
                ]}
              />
            </NextLink>
          </div>
          <div
            css={tw`relative hidden lg:flex text-sm items-center justify-center w-10/12`}
          >
            <Tabs
              value={tabValue}
              TabIndicatorProps={{
                style: {
                  height: 0,
                },
              }}
            >
              {data?.header?.menuItem?.map((link, idx) => {
                if (!link) return null;
                return (
                  <Tab
                    sx={{
                      "&.Mui-selected": {
                        color: "black",
                      },
                    }}
                    css={[
                      tw`block py-0 font-roboto tracking-tight uppercase border-solid border-transparent hover:(text-bloomsy-red border-red-500) min-w-0`,
                      {
                        borderBottomWidth: "3px",
                        fontSize: "0.85rem",
                      },
                      tabValue === idx && subMenuOpen && tw`border-red-500`,
                    ]}
                    key={link.id}
                    onMouseEnter={(e) =>
                      handleMenuOpen(idx, e.currentTarget, link?.title ?? "")
                    }
                    data-key={idx}
                    label={
                      <div css={tw`flex items-center gap-2`}>
                        <NavLinkItem
                          css={tw`uppercase font-bold font-roboto tracking-normal`}
                          data={
                            {
                              ...link,
                              shortDescription: null,
                              coverImage: null,
                            }!
                          }
                        />
                        {link.subMenuItem.length > 0 && (
                          <FiChevronDown css={tw`font-bold`} />
                        )}
                      </div>
                    }
                    aria-owns={subMenuOpen ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                  />
                );
              })}
            </Tabs>
            {data?.header?.menuItem && (
              <SubMenu
                menuItem={data?.header?.menuItem as LinkCollectionItemRecord[]}
                subMenuProps={subMProp}
              />
            )}
          </div>
          {isHeaderShrunk && isDesktop ? (
            <div
              css={tw`flex space-x-2 sm:space-x-4 lg:space-x-6 w-1/12 justify-center`}
            >
              <NavLink css={tw`hidden lg:block`} href={accountPageUrl}>
                <div css={tw`flex items-center`}>
                  <UserIcon css={tw`text-bloomsy-red w-5 h-5`} />
                </div>
              </NavLink>
              {router.asPath !== "/cart" && (
                <NavLink onClick={openCart} href="/cart">
                  <div css={tw`text-bloomsy-red flex space-x-1 sm:space-x-2`}>
                    <CartIcon css={tw`w-5 h-5`} />
                    <span css={tw`font-bold`}>{cartCount}</span>
                  </div>
                </NavLink>
              )}
            </div>
          ) : (
            <div css={tw`w-1/12`} />
          )}
        </div>
      </nav>
    </Root>
  );
};

export default PageHeader;
