/* eslint-disable react/function-component-definition */
/* eslint-disable camelcase */
import { FC, useEffect, useRef } from "react";
import { GetProductsByIdsQuery } from "src/generated/datocms-types";
import { getCmsItemIdFromCheckoutData } from "src/features/Cart/utils";
import { CartEmpty } from "src/features/Cart/CartPage/CartEmpty";
import { CartItemCard } from "src/features/Cart/CartPage/CartItemCard";
import {
  bulkRemoveItems,
  currentUpsellingList,
  useCartCount,
  useCurrentUpsellingList,
  useCurrentSubTotal,
} from "src/features/Cart/state";
import tw from "twin.macro";
import BloomsyLoader from "src/shared/components/BloomsyLoader";
import { ShopifyCartData, ShopifyLineItem } from "src/shared/types/shopify";
import { CartItemCardMobile } from "./CartItemCardMobile";

type Props = {
  products: GetProductsByIdsQuery;
  cartData: ShopifyCartData;
  variant?: "full" | "drawer";
  handleOpenOrCloseSlideCalendar?: (productId: string) => void;
  openSlideCalendar?: string;
};
export const CartItems: FC<Props> = ({
  products,
  cartData,
  variant = "full",
  handleOpenOrCloseSlideCalendar,
  openSlideCalendar,
}) => {
  const staleProducts = useRef<ShopifyLineItem[]>([]);
  const cartCount = useCartCount();
  const { addCurrentUpsellingList } = useCurrentUpsellingList();
  const { addCurrentSubTotal } = useCurrentSubTotal();

  if (currentUpsellingList.length) {
    addCurrentUpsellingList(undefined);
    addCurrentSubTotal(0);
  }

  useEffect(() => {
    if (staleProducts.current.length) {
      // todo: log this to sentry
      // console.warn("stale cart products", staleProducts.current);
      bulkRemoveItems(staleProducts.current);
      staleProducts.current = [];
    }
  }, [staleProducts]);

  if (!cartCount) {
    return <CartEmpty variant={variant} />;
  }

  return (
    <div>
      {cartData?.cart.lines.edges?.map((lineItem) => {
        const cmsProduct = products.allProducts.find(
          (product) =>
            product.id === getCmsItemIdFromCheckoutData(lineItem.node)
        );
        if (!cmsProduct) {
          // if this happens, the product exists in shopify but not the CMS,
          // We'll tell the user we're checking inventory and remove it from the cart
          staleProducts.current.push(lineItem.node);
          return (
            <div
              key={lineItem.node.merchandise?.id}
              css={[
                tw`flex items-center rounded bg-white p-3 relative mb-4`,
                {
                  boxShadow: `0 3px 15px 3px rgba(0,0,0,0.1), 0 4px 6px -2px rgba(0,0,0,0.05)`,
                },
              ]}
            >
              <BloomsyLoader css={tw`w-4 h-4 text-bloomsy-red`} />{" "}
              <span css={tw`ml-1 italic`}>Checking product inventory...</span>
            </div>
          );
        }

        return (
          <>
            {variant === "full" && (
              <CartItemCard
                key={lineItem.node.merchandise?.id}
                variant={variant}
                product={cmsProduct}
                lineItem={lineItem.node}
              />
            )}

            {variant === "drawer" && (
              <CartItemCardMobile
                key={lineItem.node.merchandise?.id}
                variant={variant}
                product={cmsProduct}
                lineItem={lineItem.node}
                handleOpenOrCloseSlideCalendar={handleOpenOrCloseSlideCalendar}
                openSlideCalendar={openSlideCalendar}
              />
            )}
          </>
        );
      })}
    </div>
  );
};
