/* eslint-disable no-console */
/* eslint-disable react/function-component-definition */
/* eslint-disable camelcase */
import tw, { theme } from "twin.macro";
import { formatPrice } from "src/shared/helpers";
import { Button } from "src/shared/components/Button";
import { useMediaQuery } from "@mui/material";
import {
  cartOperations,
  facebookApi,
  useCartBusy,
  useCartCount,
  useCartVisibility,
  useInvalidDeliveryDates,
} from "src/features/Cart/state";
import NextLink from "next/link";
import { FC, Fragment } from "react";
import { useRouter } from "next/router";
import BloomsyLoader from "src/shared/components/BloomsyLoader";
import { dataLayer } from "src/features/Analytics";
import { ShopifyCartData } from "src/shared/types/shopify";
import { StyledTooltip } from "src/features/ProductForm/styled";
import { UpSellingProducts } from "../CartDrawer/UpSelling";
import { ItemsData } from "../types/index";
import { getVariantFrequency } from "../utils";

type Props = {
  cartData: ShopifyCartData;
  variant?: "full" | "drawer";
};

const HowToCancelDetailedHTML = () => {
  return (
    <div
      css={tw`text-xs tracking-normal leading-5 text-bloomsy-midgray font-roboto`}
    >
      <p css={tw`mb-1`}>To cancel your subscription:</p>
      <ul css={tw`list-disc  ml-7`}>
        <li>Log into your account.</li>
        <li>Click on the Subscriptions tab on the top left-hand side.</li>
        <li>Scroll down to the bottom of the page and click “MANAGE”</li>
        <li>Select “Cancel Subscription.”</li>
        <li>You can also cancel by calling us at 1877-422-1316.</li>
      </ul>
    </div>
  );
};

export const CartSummary: FC<Props> = ({ cartData, variant = "full" }) => {
  const router = useRouter();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, toggleCart] = useCartVisibility();
  const cartIsBusy = useCartBusy();
  const { invalidDelivaryDatesCount } = useInvalidDeliveryDates();
  const isDesktop = useMediaQuery(`(min-width: ${theme`screens.lg`})`);
  const cartCount = useCartCount();
  const isInDrawer = variant === "drawer";

  const gotoCart = (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    router.push("/cart").then(() => window.scrollTo(0, 0));
    toggleCart();
  };
  const gotoCheckout = async (e: React.SyntheticEvent<EventTarget>) => {
    e.preventDefault();
    // Checkout started
    const itemsObj: ItemsData[] = [];
    cartData?.cart.lines.edges?.map((p) => {
      const item: ItemsData = {
        item_name: p.node?.merchandise?.product?.title,
        item_id: p.node?.merchandise?.sku,
        price: p.node?.cost?.amountPerQuantity?.amount,
        item_brand: p.node?.merchandise?.product?.vendor,
        item_category: "",
        item_category2: "",
        item_variant: p.node.merchandise?.title,
        quantity: p.node.quantity,
      };
      itemsObj.push(item);
      return true;
    });
    dataLayer({
      event: "begin_checkout",
      ecommerce: {
        items: itemsObj,
      },
    });
    await facebookApi.post({
      eventName: "InitiateCheckout",
      currency: "USD",
      products: cartData?.cart?.lines?.edges?.map((p) => ({
        productId: p.node.merchandise?.id,
        quantity: p.node.quantity,
        price: p.node?.cost?.amountPerQuantity.amount,
      })),
    });
    setTimeout(() => {
      if (cartOperations.getCheckoutUrl()?.length > 0) {
        router.replace(cartOperations.getCheckoutUrl());
      }
    }, 300);
  };

  const verifySubscriptionLabels = () => {
    const exist = cartData?.cart.lines.edges?.some((lineItem) => {
      const variantFrequency = getVariantFrequency(
        lineItem.node.merchandise?.title ?? ""
      );

      const isGift = lineItem.node?.attributes?.some(
        (p) => p.key === "_Is_Gift" && p.value === "true"
      );
      const isSub = lineItem.node?.attributes?.some(
        (p) => p.key === "_Is_Subscription_Product" && p.value === "true"
      );
      const isAutoRenew = lineItem.node?.attributes?.some(
        (p) => p.key === "_Auto_Renew" && p.value === "true"
      );
      const isSuperGift = lineItem.node?.attributes?.some(
        (p) => p.key === "SuperGiftItem" && p.value === "true"
      );
      const isPrepay = variantFrequency
        ? (variantFrequency.chargeInterval ?? 1) /
            (variantFrequency.orderInterval ?? 1) >
          1
        : false;

      const result =
        !isSuperGift &&
        ((isGift && isAutoRenew) ||
          (isPrepay && !isGift && !isSuperGift) ||
          (isSub && !isPrepay));

      return result;
    });

    return exist;
  };

  if (!cartCount) {
    return null;
  }

  return (
    <div css={[isInDrawer ? tw`mt-0` : tw`mt-6`]}>
      <div
        css={[
          tw`mt-4`,
          isInDrawer && tw`mt-0 bg-bloomsy-lightAnnouncement px-5`,
        ]}
      >
        <div css={[tw`flex items-center text-black py-7 px-0`]}>
          <div>
            <div
              css={[
                tw`uppercase font-bold tracking-wider text-3xl font-normal capitalize tracking-normal leading-9 font-roboto text-black`,
              ]}
            >
              Subtotal
            </div>
          </div>
          <div
            css={[
              tw`ml-auto font-roboto text-3xl font-roboto tracking-normal leading-9 text-black`,
            ]}
          >
            {formatPrice(
              cartData?.cart?.cost?.subtotalAmount.amount
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  +cartData?.cart.cost.subtotalAmount.amount
                : 0
            )}
          </div>
        </div>
        <div css={[tw`mt-2 pb-1`]}>
          {isInDrawer ? (
            <Fragment>
              <Button
                disabled={cartIsBusy || invalidDelivaryDatesCount > 0}
                size="small"
                // href={cartOperations.getCheckoutUrl()}
                onClick={gotoCheckout}
                rel="noopener"
                css={tw`mb-2`}
                fullWidth
              >
                {cartIsBusy ? (
                  <BloomsyLoader css={tw`w-4 h-4`} />
                ) : (
                  <span>Checkout</span>
                )}
              </Button>

              {isDesktop && (
                <Button
                  disabled={cartIsBusy}
                  variant="ghost"
                  size="small"
                  onClick={gotoCart}
                  fullWidth
                >
                  View Cart
                </Button>
              )}
            </Fragment>
          ) : (
            <Button
              disabled={cartIsBusy || invalidDelivaryDatesCount > 0}
              size="medium"
              href={cartOperations.getCheckoutUrl()}
              rel="noopener"
              fullWidth
            >
              {cartIsBusy ? (
                <BloomsyLoader css={tw`w-6 h-6`} />
              ) : (
                <span css={tw`whitespace-nowrap`}>Proceed to Checkout</span>
              )}
            </Button>
          )}
        </div>
        {!isInDrawer && (
          <div css={tw`mt-2 text-center text-bloomsy-red`}>
            <NextLink href="/" passHref legacyBehavior>
              <Button size="small" variant="text">
                Continue shopping
              </Button>
            </NextLink>
          </div>
        )}
      </div>
      {verifySubscriptionLabels() && (
        <div
          css={tw`px-5 mt-4 text-xs tracking-normal leading-5 text-bloomsy-midgray font-roboto`}
        >
          <p>Subscription products will automatically renew.</p>
          <StyledTooltip
            arrow
            disableFocusListener
            enterTouchDelay={0}
            leaveTouchDelay={8000}
            placement="bottom-start"
            title={
              <div>
                <HowToCancelDetailedHTML />
              </div>
            }
          >
            <span
              css={tw`text-xs underline inline-block align-middle cursor-pointer uppercase`}
            >
              how To Cancel
            </span>
          </StyledTooltip>
        </div>
      )}
      {isInDrawer && (
        <UpSellingProducts cartData={cartData} variant={variant} />
      )}
    </div>
  );
};
