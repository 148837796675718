import { FC } from "react";
import { useRouter } from "next/router";
import tw from "twin.macro";
import { Button } from "src/shared/components/Button";

export const PreviewNotice: FC = () => {
  const { asPath } = useRouter();
  return (
    <div
      css={tw`text-center text-white tracking-wider font-roboto block fixed bottom-0 left-0 w-full p-2 bg-bloomsy-darkred border-t border-b border-solid border-bloomsy-red z-50`}
    >
      <div css={tw`flex flex-row justify-center items-center`}>
        <Button
          title="Click to exit preview mode"
          size="small"
          href={`/api/exit-preview?redirectUri=${asPath}`}
        >
          Preview mode active. Click to exit.
        </Button>
      </div>
    </div>
  );
};
